import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, catchError, throwError } from 'rxjs';
import { Country } from '@angular-material-extensions/select-country';
import { NotificationService } from '../notification.service';

@Injectable({
  providedIn: 'root',
})
export class GeometryService {
  constructor(private http: HttpClient,
    private notificationService: NotificationService
  ) { }
  private emptyMiles = new BehaviorSubject<any>('');
  public emptyMilesItem = this.emptyMiles.asObservable();
  private longAngLatCoors = new BehaviorSubject<any>('');
  public longAngLatCoorsString = this.longAngLatCoors.asObservable();

  findAddress(id: string, item: any, alpha3Code: boolean = false): Promise<void> {
    return new Promise((resolve) => {
      const el = document.getElementById(id) as HTMLInputElement;
      const service = new google.maps.places.Autocomplete(el, {
        componentRestrictions: { country: 'us' },
      });
      service.addListener('place_changed', () => {
        const place = service.getPlace();
        console.log(place);
        
        this.parseAddress(
          item,
          place.formatted_address || '',
          place.geometry?.location,
          place.address_components,
          alpha3Code
        );

        if (id === 'google-searchp0' || id === 'google-searchppe0') {
          this.emptyMiles.next(item);
        }

        if (place && typeof item !== 'string') {
          if (
            place.business_status &&
            place.business_status === 'OPERATIONAL' &&
            place.name
          ) {
            item.businessName = place.name;
          }
          if (place.international_phone_number) {
            item.phone = place.international_phone_number;
          } else if (place.formatted_phone_number) {
            item.phone = place.formatted_phone_number;
          }
        }
        resolve();
      });

      this.emptyMiles.next('');
    });
  }

  parseAddress(point: any, address: any, location: any, components: any, alpha3Code: boolean) {
    let street = '';
    let city = '';
    let state = '';
    let zipCode = '';
    let country = '';

    if (components && components.length) {
      components.forEach((component: any) => {
        const types = component.types;
        if (types.includes('street_number')) {
          street = `${component.long_name} ${street}`.trim();
        }
        if (types.includes('route')) {
          street = `${street} ${component.long_name}`.trim();
        }
        if (types.includes('locality')) {
          city = component.long_name;
        }
        if (types.includes('administrative_area_level_1')) {
          state = component.short_name;
        }
        if (types.includes('postal_code')) {
          zipCode = component.long_name;
        }
        if (types.includes('country')) {
          country = component.short_name;
        }
      });
    }
  
    if (!street && !city && !state && !zipCode && address) {
      const split = address.split(',').map((part: string) => part.trim());
      if (split.length >= 2) {
        city = split[0];
        state = split[1];
      }
      if (split.length >= 3) {
        country = split[2];
      }
    }
  
    if (typeof point !== 'string') {
      point.get('street').setValue(street || '');
      point.get('city').setValue(city || '');
      point.get('zipCode').setValue(zipCode || '');
      point.get('stateCode').setValue(state || '');
      point.get('countryCode').setValue(country || '');
      console.log(address);
      
      const fA: string = address 
        ? address 
        : [
            point.get('street').value,
            point.get('city').value,
            point.get('stateCode').value,
            point.get('zipCode').value,
            alpha3Code ? point.get('countryCode').value.alpha3Code : point.get('countryCode').value
          ]
            .filter((value) => !!value) // Elimină valorile goale
            .join(', '); // Concatenare cu virgule
          console.log(fA);
          
      point.get('fullAddress').setValue(fA);
    }
  
    if (location) {
      if (typeof point === 'string') {
        const latitude = location.lat();
        const longitude = location.lng();
        this.longAngLatCoors.next(`${longitude},${latitude}`);
      } else {
        point.get('latitude').setValue(location.lng());
        point.get('longitude').setValue(location.lat());
      }
    }
  }

  getDirections(points?: any[]) {
    const body = { coordinates: points, radiuses: [50000] };
    return this.http.post(`https://map.zigzagplus.com/ors/v2/directions/driving-hgv`, body)
      .pipe(
        catchError((error) => {
          this.notificationService.error('Error', 'Failed to fetch directions');
          return throwError(() => error);
        })
      );
  }
  
}
