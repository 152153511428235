<div class="main-table-container">
  <div class="wrapper1">
    <div class="wrapper2" [ngClass]="emptyState && paginatedData.length == 0 ? 'center-empty-template' : ''">
      @if(paginatedData.length > 0) {
        <table id="reusable-table">
          <thead>
            <tr>
              <th class="table-selection">
                <input id="cball" class="custom-checkbox" type="checkbox" (change)="toggleSelectAll()" [checked]="isAllSelected()">
                <label for="cball"></label>
              </th>
              @for(thColumn of columns; track $index) {
                @if(thColumn.field === 'roles') {
                  <th class="role-container"  [appTooltip]="roleTooltipContent" [appTooltipPos]="roleTooltipPos"  (click)="sort(thColumn.field)">
                    <span >{{ thColumn.header }} <span [ngClass]="getSortClass(thColumn.field)"></span></span>
                    <svg-icon class="role-icon" src="assets/svg/help-circle.svg"></svg-icon>
                    <ng-template #roleTooltipContent>
                      <div class="role-tooltip">
                        <span class="title">Roles</span>
                        <div class="role-wrapper">
                          <div class="role-name">
                            <span class="admin-role-badge">Admin</span>
                            <span class="super-role-badge">Supervisor</span>
                            <span class="disp-role-badge">Dispatcher</span>
                          </div>
                          <div class="role-descr">
                            <span>This driver is disabled.</span>
                            <span>This driver replaces the previous one.</span>
                            <span>This driver replaces the previous one.</span>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </th>
                } @else if (thColumn.field === 'type') {
                  <th class="type-container" [appTooltip]="typeTooltipContent" [appTooltipPos]="typeTooltipPos" (click)="sort(thColumn.field)">
                    <span>{{ thColumn.header }}<span [ngClass]="getSortClass(thColumn.field)"></span></span>
                    <svg-icon class="type-icon" src="assets/svg/help-circle.svg"></svg-icon>
                    <ng-template #typeTooltipContent>
                      <div class="type-tooltip">
                        <span class="title">Types</span>
                        <div class="type-wrapper">
                          <div class="type-name">
                            <span class="green-badge">OB</span>
                            <span class="green-badge">OD</span>
                            <span class="blue-badge">SB</span>
                            <span class="blue-badge">SD</span>
                            <span class="orange-badge">SH</span>
                          </div>
                          <div class="type-descr">
                            <span>Original booker</span>
                            <span>Original driver</span>
                            <span>Splitted booker</span>
                            <span>Splitted driver</span>
                            <span>Shared load</span>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </th>
                } @else {
                 <th [class]="thColumn.field" style="position: relative;" (click)="sort(thColumn.field)">{{ thColumn.header }}
                   <span [ngClass]="getSortClass(thColumn.field)"></span>
                 </th>
               }
                <th *ngIf="thColumn.command"></th>
              }
            </tr>
          </thead>
          <tbody>
            @for(row of paginatedData; track $index) {
              <ng-container>
                <tr #tableRow (click)="toggleExpandRow(row)" [style.borderBottom]="isRowExpanded(row) ? 'unset' : '1pt solid #E4E7EC'">
                  <td class="table-selection">
                    <input [id]="'cb'+$index" class="custom-checkbox" type="checkbox" (change)="toggleRowSelection(row); $event.stopPropagation()" [checked]="isSelected(row)">
                    <label [for]="'cb'+$index"></label>
                  </td>
                  @for(trColumn of columns; track $index) {
                    @switch(trColumn.field) {
                      @case("userActivityStatus") {
                        <td>
                          @if(row.userActivityStatus) {
                            <div class="activ-badge">
                              <svg-icon src="assets/svg/active-status.svg"></svg-icon>Active
                            </div>
                          } @else {
                            <div class="inactiv-badge">
                              <svg-icon src="assets/svg/inactive-status.svg"></svg-icon>Inactive
                            </div>
                          }
                        </td>
                      }
                      @case("loadId") {
                        @if(row.parentId) {
                          <td class="loadId"
                            [pTooltip]="row.customLoadId" 
                            tooltipPosition="left" 
                            showDelay="800" 
                            hideDelay="200"
                          >
                            <div class="partial-load">
                              <span>{{row.customLoadId}}</span>
                              <svg-icon src="assets/svg/partial-icon.svg"></svg-icon>
                            </div>
                          </td>
                        } @else {
                          <td class="loadId"
                            [pTooltip]="row.customLoadId" 
                            tooltipPosition="left" 
                            showDelay="800" 
                            hideDelay="200"
                          >{{row.customLoadId}}</td>
                        }
                      }
                      @case("loadStatus") {
                        <td class="loadStatus">
                          <div class="load-status-badge" [style.color]="checkLoadStatus(row.loadStatus)">
                            <div class="circle" [style.backgroundColor]="checkLoadStatus(row.loadStatus)"></div>
                            {{ row.loadStatus }}
                          </div>
                        </td>
                      }
                      @case("type") {
                        <td class="typeLoad">
                          <div class="type-badge" [ngClass]="getLoadTypeColorBadge(row.type)" >
                            {{ row.type }}
                          </div>
                        </td>
                      }
                      @case("customLoadId") {
                        <td class="customLoadId"
                          (click)="detailLoadClick($event, row)"
                          [pTooltip]="row.customLoadId" 
                          tooltipPosition="left" 
                          showDelay="800" 
                          hideDelay="200"
                        >
                            {{ row.customLoadId }}
                        </td>
                      }
                      @case("loadDetails") {
                        <td class="loadDetails">
                          <div class="load-detail-badge" (click)="detailLoadClick($event, row)">
                            Detail
                            <svg-icon src="assets/svg/arrow-right-register.svg"></svg-icon>
                          </div>
                        </td>
                      }
                      @case("ratePerMile") {
                        <td class="ratePerMile"
                          [pTooltip]="row[trColumn.field]" 
                          tooltipPosition="left" 
                          showDelay="800" 
                          hideDelay="200"
                        >
                          <span>{{'$/mi '}}</span>{{ row.ratePerMile }}
                      </td>
                      }
                      @case("command") {
                        <td class="command">
                          <button (click)="onDelete(row); $event.stopPropagation()" [ngClass]="{ 'disabled-btn': !row.rightsOverUser.isDeletable }" [disabled]="!row.rightsOverUser.isDeletable">
                            <svg-icon class="edit-icon" src="assets/svg/trash.svg"></svg-icon>
                          </button>
                          <button (click)="onEdit(row); $event.stopPropagation()" [ngClass]="{ 'disabled-btn': !row.rightsOverUser.isEditable }" [disabled]="!row.rightsOverUser.isEditable">
                            <svg-icon class="edit-icon" src="assets/svg/edit-pencil.svg"></svg-icon>
                          </button>
                        </td>
                      }
                      @default {
                        <td [class]="trColumn.field" 
                          [pTooltip]="row[trColumn.field]" 
                          tooltipPosition="left" 
                          showDelay="800" 
                          hideDelay="200"
                          >
                          {{ (row[trColumn.field] !== undefined || null) ? row[trColumn.field] : '' }}
                        </td>
                      }
                    }
                  }
                </tr>
                <!-- Expanded row template -->
                <tr #expandContent style="scroll-behavior: none;">
                  <td [attr.colspan]="columns.length + 2" [hidden]="!isRowExpanded(row)">
                    <!-- <div [@expandCollapse]="isRowExpanded(row) ? 'expanded' : 'collapsed'"> -->
                      <ng-container *ngTemplateOutlet="expandedRowTemplate; context: { $implicit: row }"></ng-container>
                    <!-- </div> -->
                  </td>
                </tr>
              </ng-container>
            }
          </tbody>
          @if(isTotalVisible()) {
            <tfoot>
              <tr class="total-row">
                <td *ngFor="let column of columns">
                  @if(isTotalHidden()) {
                  <!-- Show "Total" in the first cell -->
                    <div class="total-label" *ngIf="column.totalLabel">{{column.totalLabel}}</div>
                    <!-- Show total values for columns with `showTotal: true` -->
                    <!-- total for salary page -->
                    @if(isTotalForSalary()) {
                      @for (data of paginatedData; track $index) {
                        <div class="total-cell" *ngIf="column.showTotal">{{ data.salary }}</div>
                      }
                    } @else {
                      <div class="total-cell" *ngIf="column.showTotal">{{ column.totalValue }}</div>
                    }
                  }
                  </td>
              </tr>
            </tfoot>
          }

        </table>
        
      } @else {        
        <!-- Empty state template -->
        <ng-container *ngTemplateOutlet="emptyState;"></ng-container>
      }
      @if(paginatedData.length > 0) {
        <div class="pagination">
          <button class="prev-btn" (click)="prevPage()" [disabled]="totalPages === 1 || currentPage === 1" [ngClass]="{ 'disabled-btn': totalPages === 1 || currentPage === 1 }">
            <div class="prev-text"><svg-icon src="assets/svg/arrow-left.svg"></svg-icon>Previous</div>
          </button>
          <div class="page-counters">
            @for(page of getPagesArray(); track $index) {
              <ng-container *ngIf="page === '...'">
                <span>...</span>
              </ng-container>
              <ng-container *ngIf="page !== '...'">
                <button class="counter-btn" (click)="goToPage(page)" [class.active]="currentPage === page">{{ page }}</button>
              </ng-container>
            }
          </div>
          <button class="next-btn" (click)="nextPage()" [disabled]="currentPage === totalPages" [ngClass]="{ 'disabled-btn': currentPage === totalPages  }">
            <div class="next-text">Next<svg-icon src="assets/svg/arrow-right.svg"></svg-icon></div>
          </button>
        </div>
      }
    </div>
  </div>
</div>
