import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotificationService } from './services/notification.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private notificationService: NotificationService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const allowedUrls = [
      '/api/authentication/login',
      '/api/authentication/password/send_reset_token',
      '/api/authentication/password/reset_password',
      '/api/authentication/dispatch_admin/register',
      '/api/authentication/confirm_email',
      'assets/'
    ];
    
    if (allowedUrls.some(url => request.url.includes(url))) {
      return next.handle(request);
    }
    
    const token = localStorage.getItem('token');

    // Clone the request and add the authorization header with the token
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    } else {
      // If token is not present, redirect to auth page
      this.router.navigate(['/auth/login-to-the-system']);
      return throwError(() => new Error('No JWT token found.'));
    }

    // Handle any errors
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // If 401 response is returned from API, redirect to auth page
          this.router.navigate(['/auth/login-to-the-system']);
        } else if (error.status >= 400) {
          if (error.error.level === 'WARNING') {
            this.notificationService.warning(error.error.level, error.error.message, 5000)
          } else if (error.url === 'https://map.zigzagplus.com/ors/v2/directions/driving-hgv') {
            this.notificationService.error(error.error.level, error.error.error.message, 5000)
          }
          else {
            this.notificationService.error(error.error.level, error.error.message, 5000)
          }
        }
        return throwError(() => error);
      })
    );
  }
}