import { DialogRef } from '@angular/cdk/dialog';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { LoadsService } from '../../../../../../../services/loads/loads.service';
import { Driver } from '../../../../../../../interfaces/driver/driver';
import { GeometryService } from '../../../../../../../services/geometry/geometry.service';
import { TimeLineCalendarComponent } from '../../../time-line-calendar/time-line-calendar.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponent } from '../../../../../../../components/dialog/dialog.component';
import { LoaderService } from '../../../../../../../services/loader/loader.service';
import { DialogService } from '../../../../../../../services/dialog/dialog.service';

@Component({
  selector: 'app-create-modal-note',
  templateUrl: './create-modal-note.component.html',
  styleUrls: ['./create-modal-note.component.scss'],
})
export class CreateModalNoteComponent implements OnInit {
  @Input() loadInfo: any;
  @Input() drivers: Array<Driver> = new Array<Driver>();
  @Input() start!: any;
  @Input() end!: any;
  @Output() save = new EventEmitter<void>();
  @Output() isFormValid = new EventEmitter<boolean>();
  @ViewChild('selectElement') selectElement!: MatSelect;
  reasons = ['Home', 'Shop', 'Other'];

  editLoad: boolean = false;
  dataSource: any;
  form!: FormGroup;
  isLoading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TimeLineCalendarComponent,
    public dialogRef: DialogRef<string>,
    public fb: FormBuilder,
    public loadService: LoadsService,
    public loaderService: LoaderService,
    public dialogService: DialogService,
    private geometryService: GeometryService,
  ) { }

  ngOnInit() {
    if (this.data) {
      this.dataSource = this.data
      this.editLoad = this.dataSource.editLoad
    }

    this.initForm();
    this.form.markAllAsTouched();  
    setTimeout(() => this.createAddress());
  }

  initForm() {
    this.form = this.fb.group({
      id: new FormControl(this.editLoad ? this.dataSource.note.id : 0),
      start: new FormControl(this.editLoad ? this.dataSource.note.start : this.start, Validators.required),
      end: new FormControl(this.editLoad ? this.dataSource.note.end : this.end, Validators.required),
      driverId: new FormControl(this.editLoad ? this.dataSource.note.driverId : this.loadInfo?.driverId, Validators.required),
      reason: new FormControl(this.editLoad ? this.dataSource.note.reason : '', Validators.required),
      fullAddress: new FormControl(this.editLoad ? this.dataSource.note.fullAddress : ''),
      street: new FormControl(''),
      city: new FormControl(''),
      stateCode: new FormControl(''),
      longitude: new FormControl(''),
      latitude: new FormControl(),
      zipCode: new FormControl(''),
      countryCode: new FormControl(''),
      otherReason: new FormControl(this.editLoad ? this.dataSource.note.otherReason : ''),
    });

    this.form.valueChanges.subscribe((change) => {
      this.isFormValid.emit(this.form.valid);
    });
  }

  openSelect() {
    this.selectElement.open();
  }

  createAddress() {
    this.geometryService.findAddress('google-search' + 'n', this.form);
    this.getLocations();
  }

  reasonChange = (reason: any) => {
    // if (reason === 'Other') this.form.get('otherReason')?.setValidators([Validators.required]);
    // else this.form.get('otherReason')?.clearValidators();
    // this.form.get('otherReason')?.updateValueAndValidity();
  }

  private async getLocations() {
    const pickups = this.form.get('pickups')?.value || [];
    const delivery = this.form.get('delivery')?.value || [];
    for (const el of [...pickups, ...delivery]) {
      if (!el.countryCode) {
        const regex = /^(.+),\s*(.+),\s*([A-Z]{2})\s*([\d-]+)?,\s*(.+)$/;
        const match = el.fullAddress?.match(regex);
        if (match) {
          const streetAddress = match[1];
          const city = match[2];
          const state = match[3];
          const zipCode = match[4];
          const country = match[5];

          el.street = streetAddress;
          el.city = city;
          el.zipCode = zipCode;
          el.stateCode = state;
          el.countryCode = country === 'США' ? 'USA' : country;
        }
      }
    }
  }

  saveNote() {
    this.loadService.createNote(this.form.value).subscribe();
  }

  updateNote() {
    let start = new Date(this.form.get('start')?.value)
    let end = new Date(this.form.get('end')?.value)
    this.form.get('start')?.setValue(start)
    this.form.get('end')?.setValue(end)
    this.loadService.updateNote(this.form.get('id')?.value, this.form.value).subscribe();
    
    this.dialogRef.close();
  }

  deleteNote() {
    const deletIconStyle = { stroke: '#D92D20', width: '48px', heigth: '48px' };
    const btn1Style = { background: 'white', color: '#344054', border: '1px solid #D0D5DD' };
    const btn2Style = { background: '#D92D20', color: 'white', border: '1px solid #D92D20' };

    const dialogData: any = {
      title: 'Delete',
      text: `Are you sure you want to delete this note? This action cannot be undone.`,
      button1Text: 'Cancel',
      button2Text: 'Delete',
      icon: 'custom-trash',
      iconStyle: deletIconStyle,
      dsa: true
    };

    const dialogRefference = this.dialogService.openDialog(DialogComponent, {
      props: { ...dialogData, btn1Style, btn2Style }
    });

    dialogRefference.subscribe((dialog: any) => {
      if (!dialog) return;
      switch (dialog.result) {
        case 'button1': break;
        case 'button2':
          this.loaderService.show();
          this.isLoading = true;
          this.loadService.deleteNote(this.form.get('id')?.value).subscribe(res => {
            this.isLoading = false;
            this.loaderService.hide();
            this.dialogRef.close()
          });
          break;
      }
    });
  }

  closeModal() {
    this.dialogRef.close();
  }
}
