import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AUTH_API_URL } from '../../../../../services/app-injection-tokens';
import { ReportsService } from '../../reports/reports.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SalaryService {


  constructor(
    private http: HttpClient,
    @Inject(AUTH_API_URL) private apiUrl: string
  ) { }

  getAllDispatchers(): Observable<any[]> {
    return new ReportsService(this.http, this.apiUrl).getAllDispatchers();
  }

  getSalary(formData: any): Observable<any[]> {
    const payload = {
      bypickup: formData.bypickup,
      dispatcher: formData.dispatcher?.length ? formData.dispatcher.map((m: any) => m.id).join(',') : '',
      startDate: formData.startDate.toString(),
      endDate: formData.endDate.toString(),
    };    
  
    const params = new HttpParams()
      .append('userIds', payload.dispatcher)
      .set('bypickup', payload.bypickup)
      .set('startDate', payload.startDate)
      .set('endDate', payload.endDate)
    return this.http.get<any[]>(this.apiUrl + '/api/accounting/salary', { params });
  }
  
}
