<div class="main-container">
  <mat-tab-group [animationDuration]="'800'" *ngIf="this.selectedLoad" class="tabs" animationDuration="3"
    [dynamicHeight]="true" mat-stretch-tabs="false" mat-align-tabs="start" (selectedTabChange)="changeTab($event)">
    <mat-tab style="font-size: 16px" label="Load">
      <ng-template matTabContent>
        <app-edit-load [disabledForm]="disabledForm" class="modals" [selectedLoad]="selectedLoad"
          [dispathers]="dispathers" [carriers]="carriers" [drivers]="drivers" [inputIdMap]="inputIdMap"
          (formStatusChanged)="onFormStatusChanged($event)"></app-edit-load>
      </ng-template>
    </mat-tab>
    <mat-tab class="tabs" style="font-size: 16px" label="Split">
      <ng-template matTabContent>
        <app-edit-split [selectedLoad]="selectedLoad" [dispathers]="dispathers" [carriers]="carriers"
          [allDrivers]="drivers"></app-edit-split>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
  <div class="save-container">
    <button class="remove-btn" *ngIf="selectEdit && index === 0" type="button" (click)="deleteLoad()"
      [disabled]="isLoading" [ngClass]="{ 'disabled-btn': isLoading}">
      <mat-icon class="remove-icon"><img src="../../../../../../../assets/svg/remove-img.svg"></mat-icon>
      {{"Delete"}}
    </button>
    <button *ngIf="!selectEdit && index === 0" style="width: 135px; border-radius: 4px" class="edit-btn"
      (click)="editValue()">
      {{ "Edit" }}
    </button>
    <button *ngIf="selectEdit && index === 0" style="width: 135px; border-radius: 4px" class="save-btn"
      (click)="updateLoad()" [disabled]="isLoading || !editLoadComponent.fee.valid">
      {{ "Confirm" }}
    </button>
    <button class="close-btn" (click)="closeModal()">
      <mat-icon style="cursor: pointer; width: 24px; height: 24px; color: #667085">close</mat-icon>
    </button>
  </div>
</div>