<div class="reports-wrapper">
    <div class="reports-header">
      <div class="page-titles">
        <h2 class="title">Reports</h2>
      </div>
      <div class="actions"></div>
    </div>
    <div class="reports-content-card custom-height">
      <div class="reports-content-card-header">
        <div class="reports-header-card-title">
          <p class="reports-header-card-title">{{currentReport.name}}</p>
          @if(currentReport.code !== 'DriversStatistics'){
            <div class="badge">{{filteredReports.length}} loads</div>
          } 
        </div>
        <div class="reports-content-card-subheader">
            <div class="reports-header-card-left">       
                <p-button class="gen-rep-btn" (onClick)="op.toggle($event)" icon="pi pi-sync" label="Generate Reports" severity="secondary" outlined/>
                @if(isDateCompleted() && currentReport.code !== 'DriversStatistics' && reports.length > 0) {
                  <input 
                    type="text" 
                    class="report-date"
                    pInputText 
                    [value]="headerReportDate[0] | date: 'MM/dd/yyyy' + ' - ' + (headerReportDate[1] | date: 'MM/dd/yyyy')" 
                    readonly 
                  />
                }
              
                <p-overlayPanel #op >
                  <div class="overlay-wrapper">
                    <div class="left-container">
                      <div class="left-cont-title">
                        <p>Filters</p>
                      </div>
                      <p-listbox 
                        [options]="rangeTypes" 
                        [(ngModel)]="selectedRangeType" 
                        optionLabel="name"
                        (onChange)="listChange($event)"
                      >
                      <ng-template let-range pTemplate="item">
                        <div class="flex align-items-center gap-2">
                          <div class="list-item" >
                            <ng-container *ngIf="range.code === 'custom' && dateRange; else rangeText" [style.width]="'220px'">
                              <div *ngIf="dateRange">
                                <p>{{ dateRange[0] | date: 'MM/dd/yyyy' }} - {{ dateRange[1] | date: 'MM/dd/yyyy' }}</p>
                              </div>
                            </ng-container>
                            <ng-template #rangeText>
                              {{ range.name }}
                            </ng-template>
                          </div>
                        </div>
                      </ng-template>
                      </p-listbox>
                    </div>
                    <p-divider layout="vertical" class="left-right-divider"/>
                    <div class="right-container">
                        <div class="right-content">
                          @if(isCustomCalendarVisible) {
                            <div class="calendar-wrapper">
                              <p-calendar 
                                [(ngModel)]="dateRange" 
                                selectionMode="range" 
                                [inline]="true" 
                                [numberOfMonths]="2"
                                placeholder="Select a date range"
                                dateFormat="MM/dd/yyyy"
                                (onSelect)="onDateRangeSelected()"
                              ></p-calendar>
                            </div>
                          } @else {
                            <div class="top-wrapper">
                              <div class="right-header">
                                <p>Select the required filters</p>
                                <i class="pi pi-times" (click)="op.toggle($event)"></i> 
                              </div>

                              <div class="right-content grid-container">
                                <p-dropdown 
                                  [options]="reportTypes" 
                                  [(ngModel)]="selectedReportType" 
                                  (onChange)="repTypeChange($event)"
                                  optionLabel="name"
                                  placeholder="Report"
                                  styleClass="filter-drops"
                                ></p-dropdown>
                                <p-dropdown 
                                  [options]="totalBy" 
                                  [(ngModel)]="selectedtotalBy" 
                                  [disabled]="filterFormDisabledState.totalByDisabled"
                                  optionLabel="name"
                                  placeholder="Total By"
                                  styleClass="filter-drops"
                                ></p-dropdown>

                                <!-- Broker MultiSelect -->
                                <p-multiSelect 
                                  [options]="allBrokers" 
                                  [(ngModel)]="selectedBroker" 
                                  [disabled]="filterFormDisabledState.brokerDisabled"
                                  optionLabel="businessName" 
                                  placeholder="Broker"
                                  styleClass="filter-drops"
                                  [class]="selectedBroker.length === 0 && filterFormRequiredState.brokerRequired ? 'ng-dirty ng-invalid': ''"
                                >
                                  <!-- Header Template for Select All -->
                                  <ng-template class="header-checkbox-custom" pTemplate="headercheckboxicon" let-allSelected let-partialSelected="partialSelected">
                                    <svg-icon class="cust-checked" src="/assets/svg/checked.svg" *ngIf="allSelected"></svg-icon>
                                    <svg-icon class="cust-unchecked" src="/assets/svg/checkbox-minus.svg" *ngIf="partialSelected"></svg-icon>
                                  </ng-template>

                                  <!-- Custom Checkbox for Items -->
                                  <ng-template let-item pTemplate="item" let-index="index">
                                    <div class="custom-checkbox-wrapper">
                                      <input 
                                        [id]="'broker-cb' + index" 
                                        class="custom-checkbox" 
                                        type="checkbox" 
                                        [checked]="selectedBroker?.includes(item)" 
                                        (change)="onCheckboxChange(selectedBroker, item, $event)"
                                      />
                                      <label [for]="'broker-cb' + index"></label>
                                    </div>
                                    <span class="checkbox-label">{{ item.businessName }}</span>
                                  </ng-template>
                                </p-multiSelect>

                                <!-- Carrier MultiSelect -->
                                <p-multiSelect 
                                  [options]="allCarriers" 
                                  [(ngModel)]="selectedCarrier" 
                                  [disabled]="filterFormDisabledState.carrierDisabled"
                                  optionLabel="name" 
                                  placeholder="Carrier"
                                  styleClass="filter-drops"
                                  [class]="selectedCarrier.length === 0 && filterFormRequiredState.carrierRequired ? 'ng-dirty ng-invalid': ''"
                                >
                                  <ng-template class="header-checkbox-custom" pTemplate="headercheckboxicon" let-allSelected let-partialSelected="partialSelected">
                                    <svg-icon class="cust-checked" src="/assets/svg/checked.svg" *ngIf="allSelected"></svg-icon>
                                    <svg-icon class="cust-unchecked" src="/assets/svg/checkbox-minus.svg" *ngIf="partialSelected"></svg-icon>
                                  </ng-template>

                                  <ng-template let-item pTemplate="item" let-index="index">
                                    <div class="custom-checkbox-wrapper">
                                      <input 
                                        [id]="'carrier-cb' + index" 
                                        class="custom-checkbox" 
                                        type="checkbox" 
                                        [checked]="selectedCarrier?.includes(item)" 
                                        (change)="onCheckboxChange(selectedCarrier, item, $event)"
                                      />
                                      <label [for]="'carrier-cb' + index"></label>
                                    </div>
                                    <span class="checkbox-label">{{ item.name }}</span>
                                  </ng-template>
                                </p-multiSelect>

                                <!-- Dispatcher MultiSelect -->
                                <p-multiSelect 
                                  [options]="allDispatchers" 
                                  [(ngModel)]="selectedDispatcher" 
                                  [disabled]="filterFormDisabledState.dispatcherDisabled"
                                  optionLabel="fullName" 
                                  placeholder="Dispatcher"
                                  (onChange)="dispatcherChange()"
                                  styleClass="filter-drops"
                                  [class]="selectedDispatcher.length === 0 && filterFormRequiredState.dispatcherRequired ? 'ng-dirty ng-invalid': ''"
                                >
                                  <ng-template class="header-checkbox-custom" pTemplate="headercheckboxicon" let-allSelected let-partialSelected="partialSelected">
                                    <svg-icon class="cust-checked" src="/assets/svg/checked.svg" *ngIf="allSelected"></svg-icon>
                                    <svg-icon class="cust-unchecked" src="/assets/svg/checkbox-minus.svg" *ngIf="partialSelected"></svg-icon>
                                  </ng-template>

                                  <ng-template let-item pTemplate="item" let-index="index">
                                    <div class="custom-checkbox-wrapper">
                                      <input 
                                        [id]="'dispatcher-cb' + index" 
                                        class="custom-checkbox" 
                                        type="checkbox" 
                                        [checked]="selectedDispatcher?.includes(item)" 
                                        (change)="onCheckboxChange(selectedDispatcher, item, $event)"
                                      />
                                      <label [for]="'dispatcher-cb' + index"></label>
                                    </div>
                                    <span class="checkbox-label">{{ item.fullName }}</span>
                                  </ng-template>
                                </p-multiSelect>

                                <!-- Driver MultiSelect -->
                                <p-multiSelect 
                                  [options]="allDrivers" 
                                  [(ngModel)]="selectedDriver" 
                                  [disabled]="filterFormDisabledState.driverDisabled"
                                  optionLabel="fullName" 
                                  placeholder="Driver"
                                  (onChange)="driverChange()"
                                  styleClass="filter-drops"
                                  [class]="selectedDriver.length === 0 && filterFormRequiredState.driverRequired ? 'ng-dirty ng-invalid': ''"
                                >
                                  <ng-template class="header-checkbox-custom" pTemplate="headercheckboxicon" let-allSelected let-partialSelected="partialSelected">
                                    <svg-icon class="cust-checked" src="/assets/svg/checked.svg" *ngIf="allSelected"></svg-icon>
                                    <svg-icon class="cust-unchecked" src="/assets/svg/checkbox-minus.svg" *ngIf="partialSelected"></svg-icon>
                                  </ng-template>

                                  <ng-template let-item pTemplate="item" let-index="index">
                                    <div class="custom-checkbox-wrapper">
                                      <input 
                                        [id]="'driver-cb' + index" 
                                        class="custom-checkbox" 
                                        type="checkbox" 
                                        [checked]="selectedDriver?.includes(item)" 
                                        (change)="onCheckboxChange(selectedDriver, item, $event)"
                                      />
                                      <label [for]="'driver-cb' + index"></label>
                                    </div>
                                    <span class="checkbox-label">{{ item.fullName }}</span>
                                  </ng-template>
                                </p-multiSelect>

                                <!-- Vehicle MultiSelect -->
                                <p-multiSelect 
                                  [options]="allVehicles" 
                                  [(ngModel)]="selectedVehicle" 
                                  [disabled]="filterFormDisabledState.vehicleDisabled"
                                  optionLabel="vehicle_unit" 
                                  placeholder="Vehicle"
                                  styleClass="filter-drops"
                                  [class]="selectedVehicle.length === 0 && filterFormRequiredState.vehicleRequired ? 'ng-dirty ng-invalid': ''"
                                >
                                  <ng-template class="header-checkbox-custom" pTemplate="headercheckboxicon" let-allSelected let-partialSelected="partialSelected">
                                    <svg-icon class="cust-checked" src="/assets/svg/checked.svg" *ngIf="allSelected"></svg-icon>
                                    <svg-icon class="cust-unchecked" src="/assets/svg/checkbox-minus.svg" *ngIf="partialSelected"></svg-icon>
                                  </ng-template>

                                  <ng-template let-item pTemplate="item" let-index="index">
                                    <div class="custom-checkbox-wrapper">
                                      <input 
                                        [id]="'vehicle-cb' + index" 
                                        class="custom-checkbox" 
                                        type="checkbox" 
                                        [checked]="selectedVehicle?.includes(item)" 
                                        (change)="onCheckboxChange(selectedVehicle, item, $event)"
                                      />
                                      <label [for]="'vehicle-cb' + index"></label>
                                    </div>
                                    <span class="checkbox-label">{{ item.vehicle_unit }}</span>
                                  </ng-template>
                                </p-multiSelect>

                                <!-- Status MultiSelect -->
                                <p-multiSelect 
                                  [options]="allStatus" 
                                  [(ngModel)]="selectedStatus" 
                                  [disabled]="filterFormDisabledState.statusDisabled"
                                  optionLabel="name" 
                                  placeholder="Status"
                                  [class]="selectedStatus.length === 0 && filterFormRequiredState.statusRequired ? 'ng-dirty ng-invalid': ''"
                                  styleClass="filter-drops"
                                >
                                  <ng-template class="header-checkbox-custom" pTemplate="headercheckboxicon" let-allSelected let-partialSelected="partialSelected">
                                    <svg-icon class="cust-checked" src="/assets/svg/checked.svg" *ngIf="allSelected"></svg-icon>
                                    <svg-icon class="cust-unchecked" src="/assets/svg/checkbox-minus.svg" *ngIf="partialSelected"></svg-icon>
                                  </ng-template>

                                  <ng-template let-item pTemplate="item" let-index="index">
                                    <div class="custom-checkbox-wrapper">
                                      <input 
                                        [id]="'status-cb' + index" 
                                        class="custom-checkbox" 
                                        type="checkbox" 
                                        [checked]="selectedStatus?.includes(item)" 
                                        (change)="onCheckboxChange(selectedStatus, item, $event)"
                                      />
                                      <label [for]="'status-cb' + index"></label>
                                    </div>
                                    <span class="checkbox-label">{{ item.name }}</span>
                                  </ng-template>
                                </p-multiSelect>
                              </div>
                            </div>
                          }
                          <!-- Btns -->
                          <div class="bottom-wrapper">

                            <div class="left">
                              <div class="toggle-containder">
                                <input (click)="toggleSelectAll()" class="apple-switch" type="checkbox"
                                  [(ngModel)]="selectAllFilters">
                                <span (click)="toggleSelectAll()" class="toggle-text">Select all filters</span>
                              </div>
                              <!-- <p-button 
                                class="save-tpl-btn" 
                                (onClick)="{}" 
                                label="Save as template" 
                                severity="secondary" 
                                [text]="true"
                              >
                              <svg-icon src="assets/svg/bookmark.svg"></svg-icon>
                            </p-button> -->
                            </div>
                            <div class="right">
                              @if(isCustomCalendarVisible) {
                                <p-button 
                                  class="filter-btns" 
                                  outlined 
                                  severity="secondary" 
                                  (onClick)="backToFilter()" 
                                  label="Back to filters"
                                ></p-button>
                              } @else {
                                <p-button 
                                  class="filter-btns" 
                                  outlined 
                                  severity="secondary" 
                                  (onClick)="clearFilters()" 
                                  label="Reset"
                                ></p-button>
                              }
                              <div 
                                [pTooltip]="getFilterTooltipText()" 
                                [tooltipDisabled]="!isGenerateDisabled()" 
                                showDelay="200" 
                                hideDelay="200"
                              >
                              <button 
                                pButton 
                                class="filter-btns" 
                                (click)="generateClick()" 
                                label="Generate" 
                                [disabled]="isGenerateDisabled()"
                              ></button>
                            </div>
                            
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </p-overlayPanel>
              <!-- <p-dropdown 
                  [options]="templates" 
                  [(ngModel)]="selectedTmpl" 
                  optionLabel="name"
                  placeholder="Templates" 
                  styleClass="template-drop"
                /> -->
            </div>
            <div class="reports-header-card-right">
                
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input #searchInput type="text" mode="Search" placeholder="Search" pInputText [(ngModel)]="filterText" (input)="applyFilter()" />
                </span>

                <p-button class="save-rep" 
                    severity="secondary" 
                    outlined 
                    (onClick)="menu.toggle($event)">
                    <span>
                        <i class="pi pi-cloud-download"></i> Download
                        <i class="pi pi-chevron-down"></i>
                    </span>
                </p-button>
                <p-tieredMenu #menu [model]="items" [popup]="true" />

            </div>
        </div>
      </div>
      @if(currentReport.code === 'DriversStatistics') {
        <div class="driver-statistics-rep-container">
          <div 
            class="driver-statistics-rep" 
            *ngFor="let report of filteredReports; let i = index;" 
            [ngClass]="{ 'hide-total-footer': i !== filteredReports.length - 1 }">
            <div class="dsr-header">
              <div class="dsr-wrapper">
                <span class="rep-driver-name"> {{ report.driverName }} </span>
                <span class="rep-driver-date">
                  <p>{{ '(' + (headerReportDate[0] | date: 'MM/dd/yyyy') + ' - ' + (headerReportDate[1] | date: 'MM/dd/yyyy') + ')' }}</p>
                </span>
              </div>
            </div>
            <app-reusable-table 
              [data]="[report]" 
              [columns]="columns" 
              [pageSize]="filteredReports.length"
            >
            </app-reusable-table>
          </div>
        </div>
      } @else {
          @defer (when isRepGenerated) {
            @if(filteredReports.length === 0) {
              <div class="empty-reports">
                <svg-icon src="assets/svg/search-faded-icon.svg"></svg-icon>
                <p>No reports found</p>
              </div>
            } @else {
              <app-reusable-table 
                [ngClass]="{ 'shorten-route-col': currentReport.code !== 'TotalRevenueReport' }"
                [data]="filteredReports" 
                [drivers]="driverDetailSource"
                [dispatcers]="allDispatchers"
                [carriers]="allCarriers"
                [columns]="columns" 
                [pageSize]="filteredReports.length"
              >
              </app-reusable-table>
            }
          }
      }
    </div>
  </div>
  <p-toast />