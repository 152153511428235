import { Component, EventEmitter, Input, OnChanges, Output, signal, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import {
  CountryISO,
  SearchCountryField,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input-gg';
import { UsersPageService } from '../../../../services/users/users.service';
import { LoaderService } from '../../../../../../services/loader/loader.service';

@Component({
  selector: 'app-create-user-form',
  templateUrl: './create-user-form.component.html',
  styleUrls: ['./create-user-form.component.scss', '../../../../main.module.styles.scss']
})
export class CreateUserFormComponent implements OnChanges{

  @Output() formValid = new EventEmitter<any>();
  @Input() selectSuperVisor!: boolean;
  
  constructor(
    private formBuilder: FormBuilder,
    private usersPageService: UsersPageService,
    private loaderService: LoaderService
  ) {
    this.userForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      supervisorId: ["", [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required]],
      password: new FormControl(null, [
        (c: AbstractControl) => Validators.required(c),
        Validators.pattern(/^(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$/)
      ]),
      password_confirm: new FormControl(null, [
        (c: AbstractControl) => Validators.required(c),
        Validators.pattern(/^(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$/)
      ])
    });

    this.userForm.addValidators(
      this.matchPassValidator('password', 'password_confirm')
    );

    this.userForm.valueChanges.subscribe(() => {
      this.isDirty = Object.keys(this.userForm.controls).some(key => this.userForm.get(key)?.value);
      this.emitFormValidity();
    });
  }

  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  userForm: FormGroup;
  
  public superVisorsSource:Array<any> = new Array<any>();
  public fieldTextType: string = 'password';
  public isDirty: boolean = false;

  ngOnInit(): void {
    this.getSupervisors();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['selectSuperVisor']) {
      this.additionallyValidCheck();
    }
  }
  
  matchPassValidator(controlName: string, matchingControlName: string): ValidatorFn {
    return (abstractControl: AbstractControl) => {
        const control = abstractControl.get(controlName);
        const matchingControl = abstractControl.get(matchingControlName);

        if (matchingControl!.errors && !matchingControl!.errors?.['confirmedValidator']) {
            return null;
        }

        if (control!.value !== matchingControl!.value) {
          const error = { confirmedValidator: 'Passwords do not match.' };
          matchingControl!.setErrors(error);
          return error;
        } else {
          matchingControl!.setErrors(null);
          return null;
        }
    }    
  }

  additionallyValidCheck() {
    if(this.selectSuperVisor) {
      this.userForm.get('supervisorId')?.setValidators([Validators.required]);
    } else {
      this.userForm.get('supervisorId')?.clearValidators();
    }
    this.userForm.get('supervisorId')?.updateValueAndValidity();
  }

  emitFormValidity() {
    this.formValid.emit({isValid:this.userForm.valid});
    if(this.userForm.valid) this.formValid.emit({isValid:this.userForm.valid, formData: this.userForm.value});
  }

  getSupervisors() {
    this.loaderService.show();
    this.usersPageService.getSupervisors().subscribe(data => {
      this.loaderService.hide();
      this.superVisorsSource = data;
    },
    error => {
      this.loaderService.hide();
      console.error('Error fetching users', error);
    });
  }

  togglePassType() {
    this.fieldTextType = this.fieldTextType === 'password' ? 'text' : 'password';    
  }
}