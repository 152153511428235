import { Component, OnDestroy, OnInit, ViewChild, viewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { JwtHelperService } from '@auth0/angular-jwt';
import { forkJoin, map, Subscription, tap } from 'rxjs';
import { DialogService } from '../../../../services/dialog/dialog.service';
import { LoaderService } from '../../../../services/loader/loader.service';
import { ReportsService } from './reports.service';
import { Item } from '../../../../interfaces/item';
import { MenuItem, MessageService } from 'primeng/api';
import { TieredMenu } from 'primeng/tieredmenu';
import { Carrier } from '../../../../interfaces/carrier';
import { Dispatcher } from '../manage-pages/dispatch/interfaces/dispatcher';
import { Broker } from '../../../../interfaces/loads/broker';
import { DataService } from '../../../../services/reference-data/reference-data.service';
import { formatDate } from '@angular/common';
import { OverlayPanel } from 'primeng/overlaypanel';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable, { RowInput } from 'jspdf-autotable';
declare module 'jspdf' {
  interface jsPDF {
    lastAutoTable: {
      finalY: number;
    };
  }
}

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss', '../../main.module.styles.scss']
})
export class ReportsComponent implements OnInit, OnDestroy{

  @ViewChild(TieredMenu) tieredMenu!: TieredMenu;
  @ViewChild(OverlayPanel) overlayPanel!: OverlayPanel;

  constructor (
    private reportsService: ReportsService,
    private dialog: DialogService,
    private loaderService: LoaderService,
    private dialog1: MatDialog,
    private jwtHelper: JwtHelperService,
    private dataService: DataService,
    private messageService: MessageService
  ) {
    this.items = [
      {
          label: 'PDF',
          disabled: true,
          command: () => {
            this.selectedReportType.code === 'DriversStatistics'
            ? this.saveDriverStatisticsPDF()
            : this.saveOthersPDF()
          }
      },
      {
          label: 'XSLX',
          disabled: true,
          command: () => {
            this.exportTableToExcel();
          }
      },
    ]
  }

  public allBrokers: Broker[] = [];
  public allCarriers: Carrier[] = [];
  public allDispatchers: Dispatcher[] = []
  public allDrivers: any[] = [];
  public driverDetailSource: any[] = []; // detail load popup click
  public allVehicles: any[] = [];
  public allStatus: any[] = []; 

  private copyDispatcher: Dispatcher[] = [];
  private copyDriver: any[] = [];
  private copyVehicle: any[] = [];
  private isTotalVisible: string = '';
  public selectedBroker: Broker[] = [];
  public selectedCarrier: Carrier[] = [];
  public selectedDispatcher: Dispatcher[] = [];
  public selectedDriver: any[] = [];
  public selectedVehicle: any[] = [];
  public selectedStatus: any[] = [];

  private itemsSubscription: Subscription = new Subscription;
  public selectAllFilters:boolean = false;
  public filterFormDisabledState: any = {
    brokerDisabled: false,
    carrierDisabled: false,
    dispatcherDisabled: false,
    driverDisabled: false,
    vehicleDisabled: false,
    statusDisabled: false,
  }
  public filterFormRequiredState: any = {
    brokerRequired: false,
    carrierRequired: false,
    dispatcherRequired: false,
    driverRequired: false,
    vehicleRequired: false,
    statusRequired: false,
  }
  public reports: any[] = [];
  public filteredReports: any[] = [];
  public filterText: string = ''; 
  public items: MenuItem[];
  public reportTypes: Item[] = [
    {code: 'TotalRevenueReport', name: 'Total Revenue Report'},
    {code: 'RatePerMileDispatcher', name: 'Rate per Mile (by Dispatcher)'},
    {code: 'RatePerMileDriver', name: 'Rate per Mile (by Driver)'},
    {code: 'DriversStatistics', name: `Driver's Statistics`},
  ]
  public selectedReportType: Item = this.reportTypes[0];
  public currentReport: Item = structuredClone(this.selectedReportType);
  public rangeTypes: Item[] = [
    {code: '7', name: 'Last 7 days'},
    {code: '30', name: 'Last 30 days'},
    {code: '93', name: 'Last 3 months'},
    {code: '183', name: 'Last 6 months'},
    {code: '365', name: 'Last year'},
    {code: 'custom', name: 'Custom range'},
  ]
  public selectedRangeType: Item = this.rangeTypes[0];

  public totalBy : Item[] = [
    {code: 'pickUp', name: 'By pick up'},
    {code: 'delivery', name: 'By delivery'},
  ]
  public selectedtotalBy: Item = this.totalBy[0];
  public dateRange: Date[] | undefined;
  public headerReportDate: Date[] | undefined;
  public templates: Array<Item> = [
    {
      type: '',
      code: '1',
      name: 'myTemplate1',
      description: ''
    },
    {
      type: '',
      code: '2',
      name: 'myTemplate2',
      description: ''
    }
  ];
  public isRepGenerated: boolean = false;
  public isCustomCalendarVisible = false;
  public selectedTmpl: Array<Item> = new Array<Item>();

  public columns: any[] = [];

  private totalRevenuTableCols = [
    { field: 'customLoadId', header: 'Load' },
    { field: 'type', header: 'Type' },
    { field: 'pickUpDate', header: 'Pickup Date' },
    { field: 'deliveryDate', header: 'Delivery Date' },
    { field: 'truck', header: 'Truck' },
    { field: 'trailer', header: 'Trailer' },
    { field: 'driver', header: 'Driver' },
    { field: 'dispatcher', header: 'Dispatcher' },
    { field: 'route', header: 'Route', totalLabel: 'Total:' },
    { field: 'emptyMiles', header: 'D/H',showTotal: true, totalValue: '' },
    { field: 'loadedMiles', header: 'Loaded Miles',showTotal: true, totalValue: '' },
    { field: 'totalMiles', header: 'Total Miles', showTotal: true, totalValue: ''},
    { field: 'rate', header: 'Rate',showTotal: true, totalValue: '' },
  ];

  private ratePerMileTableCols = [
    ...this.totalRevenuTableCols,
    { field: 'ratePerMile', header: 'Rate Per Mile',showTotal: true, totalValue: '' }
  ];

  private driverStatisticsCols = [
    { field: 'workingDays', header: 'Working Days' },
    { field: 'daysOff', header: 'Days Off' },
    { field: 'deadHeadPercentage', header: 'D/H',totalLabel: 'Total:' },
    { field: 'loadedMiles', header: 'Loaded Miles', showTotal: true, totalValue: '' },
    { field: 'totalMiles', header: 'Total Miles', showTotal: true, totalValue: '' },
    { field: 'ratePerMile', header: 'Rate per mile', showTotal: true, totalValue: '' },
    { field: 'gross', header: 'Gross', showTotal: true, totalValue: '' },
  ]

  ngOnInit(): void {
    this.startItemsObserver();
    this.getFilterData();

    this.setDisabledStateForm(false);
    this.setRequiredStateForm({dispatcherRequired: true});
  }

  ngOnDestroy(): void {
    this.itemsSubscription.unsubscribe();
  }

  formatDateRange(dateRange: Date[]): string {
    if (!dateRange || dateRange.length !== 2) return '';
    const [start, end] = dateRange;
    return `${this.formatDate(start)} - ${this.formatDate(end)}`;
  }

  formatDate(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  }

  normalizeValue(value: string): number | string {
    if (!value || typeof value !== 'string') {
      return value; // Return the original value if it's not a string
    }
  
    // Remove specific patterns except ' (60%)'
    const patternsToRemove = ['$/mi'];
    patternsToRemove.forEach(pattern => {
      value = value.replace(new RegExp(`\\${pattern}`, 'g'), ''); // Remove patterns like '$' and '$/mi'
    });
  
    // Remove commas for consistent number parsing
    value = value.replace(/,/g, '');
  
    // Convert the cleaned value to a number if possible
    const numericValue = parseFloat(value);
  
    // Return the numeric value if valid, otherwise the cleaned string
    return isNaN(numericValue) ? value.trim() : numericValue;
  }

  saveOthersPDF(): void {
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: 'a4',
    });
    const columnsToInclude = this.columns.filter(col => col.field !== 'truck' && col.field !== 'trailer');
    const data: RowInput[] = this.filteredReports.map(report => columnsToInclude.map(col => report[col.field]));    
    
    const columns: string[] = columnsToInclude.map(col => col.header);
    const columnIndexMap: Record<string, number> = columnsToInclude.reduce((map, col, index) => {
      map[col.field] = index;
      return map;
    }, {} as Record<string, number>);

    let isFirstPage = true; // Flag pentru a determina prima pagină
    const title = this.selectedReportType.name;
    const subTitle = this.headerReportDate
    ? `(${this.formatDate(this.headerReportDate[0])} - ${this.formatDate(this.headerReportDate[1])})`
    : 'Date not available';
    
    const total = this.columns.find(col=> col.field === 'route');
    let footerRow: string[] = [];
    // Generate footer row dynamically
    if(total.totalLabel && total.totalLabel.length > 0) {
      footerRow = columnsToInclude.map(column => {
        if (column.totalLabel) {
          return column.totalLabel;
        }
        if (column.showTotal) {
          if(column.field === 'ratePerMile') return this.normalizeValue(column.totalValue.toString())
          else return column.totalValue || '';
        }
        return ''; // Empty cell for columns without totals
      });      
    }

    const totalRevenueColumnStyles: any = {
      [columnIndexMap['type']]: { cellWidth: 10 },
      [columnIndexMap['customLoadId']]: { cellWidth: 25 },
      [columnIndexMap['driver']]: { cellWidth: 28 },
      [columnIndexMap['emptyMiles']]: { cellWidth: 25 },
      [columnIndexMap['loadedMiles']]: { cellWidth: 30 },
      [columnIndexMap['pickUpDate']]: { cellWidth: 25 },
      [columnIndexMap['deliveryDate']]: { cellWidth: 25 },
      [columnIndexMap['totalMiles']]: { cellWidth: 25 },
      [columnIndexMap['rate']]: { cellWidth: 20 },
      [columnIndexMap['dispatcher']]: { cellWidth: 30 },
      [columnIndexMap['route']]: { cellWidth: 50 },
      [columnIndexMap['ratePerMile']]:  { cellWidth: 20 },
    };

    const ratePerMileColumnStyles: any = {
      [columnIndexMap['type']]: { cellWidth: 12 },
      [columnIndexMap['customLoadId']]: { cellWidth: 23 },
      [columnIndexMap['driver']]: { cellWidth: 28 },
      [columnIndexMap['emptyMiles']]: { cellWidth: 25 },
      [columnIndexMap['loadedMiles']]: { cellWidth: 30 },
      [columnIndexMap['pickUpDate']]: { cellWidth: 23 },
      [columnIndexMap['deliveryDate']]: { cellWidth: 23 },
      [columnIndexMap['totalMiles']]: { cellWidth: 25 },
      [columnIndexMap['rate']]: { cellWidth: 20 },
      [columnIndexMap['dispatcher']]: { cellWidth: 24 },
      [columnIndexMap['route']]: { cellWidth: 40 },
      [columnIndexMap['ratePerMile']]:  { cellWidth: 20 },
    };

    autoTable(doc, {
      head: [columns], 
      body: data,   
      foot: footerRow ? [footerRow] : [],
      showFoot: 'lastPage',
      startY: 20, // Start pentru prima pagină
      theme: 'striped',
      margin: { top: 5, bottom: 5, left: 2, right: 2 },
      didDrawPage: (data) => {
        if (isFirstPage) {
          // Adaugă titlul și subtitlul pe prima pagină
          doc.setFontSize(16);
          doc.text(title, 14, 10);
          doc.setFontSize(10);
          doc.text(subTitle, 20, 16);
          isFirstPage = false;
        } else {
          data.settings.startY = 0;
        }
      },
      styles: {
        fontSize: 10,
        cellPadding: 1,
        lineWidth: {
          bottom: 0.1,
          top: 0.1
        },
        lineColor: '#e4e7ec',
      },
      headStyles: {
        valign: "middle",
        fillColor: [40, 40, 40],
        textColor: [255, 255, 255],
      },
      alternateRowStyles: {
        fillColor: [245, 245, 245], 
      },
      footStyles: {
        fillColor: '#F9FAFB',
        textColor: '#475467',
        fontStyle: 'bold',
      },
      columnStyles: this.selectedReportType.code === 'TotalRevenueReport' ? totalRevenueColumnStyles : ratePerMileColumnStyles
    });
    const dateRange = this.headerReportDate
    ? `${this.formatDate(this.headerReportDate[0])}-${this.formatDate(this.headerReportDate[1])}`
    : 'Date not available';
    
    doc.save(`${this.currentReport.code} (${dateRange}).pdf`);
  }

  saveDriverStatisticsPDF(): void {
    const doc = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      format: 'a4',
    });
  
    const pageHeight = doc.internal.pageSize.height;
    const marginY = 10;
    const textHeight = 10; // Înălțimea pentru `driverName` și `dateRange`
    let startY = marginY;
  
    const dateRange = this.headerReportDate
      ? `${this.formatDate(this.headerReportDate[0])} - ${this.formatDate(this.headerReportDate[1])}`
      : 'Date not available';
  
    const title = this.selectedReportType.name;
    doc.setFontSize(16);
    doc.text(title, 14, startY);
    startY += 10;
  
    const columns: string[] = this.driverStatisticsCols.map(col => col.header);
    const columnIndexMap: Record<string, number> = this.driverStatisticsCols.reduce((map, col, index) => {
      map[col.field] = index;
      return map;
    }, {} as Record<string, number>);
    
    let pageCount = 0;
    let tablesOnCurrentPage = 0;

    this.filteredReports.forEach((report, index) => {
      if (tablesOnCurrentPage >= 6) {
        doc.addPage();
        startY = marginY;
        pageCount = 0;
        tablesOnCurrentPage = 0;
      }
      const tableData: RowInput[] = [this.driverStatisticsCols.map(col => this.normalizeValue(report[col.field]))];
      
      const estimatedTableHeight = 10 + tableData.length * 10;
      const totalEstimatedHeight = estimatedTableHeight + textHeight;
  
      if (startY + totalEstimatedHeight > pageHeight - marginY) {
        doc.addPage();
        startY = marginY;
        pageCount++;
        tablesOnCurrentPage = 0;
      }

      const driverName: string = report.driverName || '';
      const startX = 14;
  
      doc.setFontSize(14);
      doc.text(driverName, startX, startY + 5);
      const dateRangeX = startX + doc.getTextWidth(driverName) + 5;
  
      doc.setFontSize(10);
      doc.text(dateRange, dateRangeX, startY + 5);
      startY += 5;
  
      const autoTableOptions: any = {
        head: [columns],
        body: tableData,
        startY: startY + 5,
        theme: 'striped',
        margin: { left: 2, right: 2 },
        styles: {
          fontSize: 10,
          cellPadding: 3,
        },
        headStyles: {
          fillColor: [40, 40, 40],
          textColor: [255, 255, 255],
        },
        alternateRowStyles: {
          fillColor: [245, 245, 245],
        },
        footStyles: {
          fillColor: '#F9FAFB',
          textColor: '#475467',
          fontStyle: 'bold',
        },
        columnStyles: {
          [columnIndexMap['workingDays']]: { cellWidth: 30 },
          [columnIndexMap['daysOff']]: { cellWidth: 25 },
          [columnIndexMap['deadHeadPercentage']]: { cellWidth: 25 },
          [columnIndexMap['loadedMiles']]: { cellWidth: 35 },
          [columnIndexMap['totalMiles']]: { cellWidth: 28 },
          [columnIndexMap['ratePerMile']]: { cellWidth: 35 },
          [columnIndexMap['gross']]: { cellWidth: 28 },
        },
      };
      const total = this.columns.find(col=> col.field === 'deadHeadPercentage');
      // Adaugă footer doar pentru ultima tabelă
      if (index === this.filteredReports.length - 1) {
        const footerRow: string[] = this.driverStatisticsCols.map(column => {
          if (column.totalLabel) {
            return column.totalLabel;
          }
          if (column.showTotal) {
            return column.totalValue ? (this.normalizeValue(column.totalValue.toString())).toString() : '';
          }
          return '';
        });
  
        autoTableOptions.foot = (total.totalLabel && total.totalLabel.length > 0) ? [footerRow] : [''];
      }
  
      autoTable(doc, autoTableOptions);
      startY = doc.lastAutoTable.finalY + 10;
      tablesOnCurrentPage++;
    });
  
    doc.save(`${this.currentReport.code} (${dateRange}).pdf`);
  }

  exportTableToExcel(): void {
    const tableData = this.formatTableData(this.filteredReports);
    const footerData = this.getFooterData();

    if (footerData) {
      tableData.push(footerData);
    }

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(tableData);
    const workbook: XLSX.WorkBook = { Sheets: { 'Data': worksheet }, SheetNames: ['Data'] };
    const dateRange = this.headerReportDate
    ? `${this.formatDate(this.headerReportDate[0])}-${this.formatDate(this.headerReportDate[1])}`
    : 'Date not available';

    XLSX.writeFile(workbook, `${this.currentReport.code}-${dateRange}.xlsx`);
  }

  formatTableData(data: any[]): any[] {
    return data.map(item => {

      const formattedItem: any = {};
      if (this.selectedReportType.code === 'DriversStatistics') {
        formattedItem['Driver Name'] = item.driverName;
      }

      this.columns.forEach(column => {
        formattedItem[column.header] = item[column.field];
      });

      return formattedItem;
    });
  }  

  getFooterData(): any {
    const footerRow: any = {};
    if (this.selectedReportType.code === 'DriversStatistics') {
      footerRow['Driver Name'] = '';
    }

    this.columns.forEach(column => {
      if (column.totalLabel) {
        footerRow[column.header] = column.totalLabel;
      } else if (column.showTotal) {
        footerRow[column.header] = column.totalValue;
      } else {
        footerRow[column.header] = '';
      }
    });

    return footerRow;
  }
  
  showCustomCalendar(): void {
    this.isCustomCalendarVisible = true;
  }

  isRangeCompleted(): boolean {
    return this.dateRange && this.dateRange.every(date => date) || false;
  }

  isDateCompleted(): boolean {
    return this.headerReportDate && this.headerReportDate.every(date => date) || false;
  }

  onDateRangeSelected(): void {
    if (this.isRangeCompleted()) {
      this.isCustomCalendarVisible = false;
    } else {
      this.isCustomCalendarVisible = true;
    }
    this.headerReportDate = this.dateRange;
  }

  dispatcherChange() {
    if(this.selectedDispatcher.length > 0) this.selectedDriver = this.allDrivers.filter(f=> this.selectedDispatcher.some(d=> d.id === f.dispatcherId));
    else this.selectedDriver = [];
  }

  driverChange() {
    if(this.selectedDriver.length > 0) this.selectedVehicle = this.allVehicles.filter(v=> this.selectedDriver.some(d=> d.vehicleId === v.id));
    else this.allDrivers = this.copyDriver;
  }

  repTypeChange(event:any) {
    // this.reports.length = this.filteredReports.length = 0;
    this.clearFilters();
    // this.isRepGenerated = false;
    this.items.forEach(element => element.disabled = this.reports.length === 0);

    switch (event.value.code) {
      case "TotalRevenueReport":
        this.setDisabledStateForm(false);
        this.setRequiredStateForm({dispatcherRequired: true});
      break;
      case "RatePerMileDispatcher":
        this.setDisabledStateForm(false);
        this.setRequiredStateForm({dispatcherRequired: true});
      break;
      case "RatePerMileDriver":
        this.setDisabledStateForm(false);
        this.setRequiredStateForm({driverRequired: true});
      break;
      case "DriversStatistics":
        this.setDisabledStateForm(true);
        this.setRequiredStateForm({driverRequired: true});
      break;
    
      default:
        break;
    }
  }

  clearFilters() {
    this.selectedBroker = [];
    this.selectedCarrier = [];
    this.selectedDispatcher = [];
    this.selectedDriver = [];
    this.selectedVehicle = [];
    this.selectedStatus = [];
    this.selectAllFilters = false;
  }

  getFilterTooltipText(): string {
    if(!this.selectedRangeType) return 'Select time range filter/Select all filters';
    else return 'Select all filters';
  }

  toggleSelectAll() {
    this.selectAllFilters = !this.selectAllFilters;

    if (this.selectAllFilters) {
      if(this.selectedReportType.code === 'DriversStatistics') this.selectedDriver = [...this.allDrivers];
      else {
        this.selectedDispatcher = [...this.allDispatchers];
        this.selectedDriver = [...this.allDrivers];
        this.selectedBroker = [...this.allBrokers];
        this.selectedCarrier = [...this.allCarriers];
        this.selectedVehicle = [...this.allVehicles];
        this.selectedStatus = [...this.allStatus];
      }
    } else {
      this.selectedBroker = [];
      this.selectedCarrier = [];
      this.selectedDispatcher = [];
      this.selectedDriver = [];
      this.selectedVehicle = [];
      this.selectedStatus = [];
    }
  }

  onCheckboxChange(selection: any[], item: any, event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      if (!selection.includes(item)) selection.push(item);
    } else {
      const index = selection.indexOf(item);
      if (index !== -1) {
        selection.splice(index, 1);
      }
    }
  }

  startItemsObserver() {
    this.itemsSubscription = this.dataService.items$.subscribe(items => {
      items.map(item => {
        switch (item.type) {
          case 'LoadStatusLoad': this.allStatus.push(item); break;
        }
      });
    });
  }

  getTotalRevenueReports(formData: any): void {
    this.loaderService.show();
    this.filterText = '';
    this.reportsService.totalRevenue(formData).pipe(
      tap(data => {
        this.columns = this.totalRevenuTableCols;
        this.setTotals(data);
      }),
      map((data: any) => {
        return (data.totalRevenueReport as Array<any>).map(report => ({
          ...report,
          loadedMiles: `${report.loadedMiles} (${report.loadedMilesPercent}%)`,
          emptyMiles: `${report.emptyMiles} (${report.emptyMilesPercent}%)`,
          rate: `$${report.rate}`,
        }));
      })
    ).subscribe({
      next: transformedData => {
        if(transformedData.length == 0) this.reports = [];
        else this.reports = transformedData;
        this.isRepGenerated = true;
        this.items.forEach(element => element.disabled = this.reports.length === 0);        
        this.applyFilter();
      },
      error: error => {
        this.loaderService.hide();
        this.reports.length = this.filteredReports.length = 0;
        // this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error fetching reports' });
        console.error('Error fetching reports', error);
      },
      complete: () => {
        this.loaderService.hide();
        console.log(this.overlayPanel);
        this.overlayPanel.hide();
      }
    });
  }

  getRatePerMiReports(formData: any): void {
    this.loaderService.show();
    this.filterText = '';
    this.reportsService.ratePerMi(formData).pipe(
      tap(data => {
        this.columns = this.ratePerMileTableCols;
        this.setTotals(data);
      }),
      map((data: any) => {
        return (data.ratePerMileReport as Array<any>).map(report => ({
          ...report,
          loadedMiles: `${report.loadedMiles} (${report.loadedMilesPercent}%)`,
          emptyMiles: `${report.emptyMiles} (${report.emptyMilesPercent}%)`,
          rate: `$${report.rate}`,
        }));
      })
    ).subscribe({
      next: transformedData => {
        if(transformedData.length == 0) this.reports = [];
        else this.reports = transformedData;
        this.items.forEach(element => element.disabled = this.reports.length === 0);
        this.isRepGenerated = true;
        this.applyFilter();
      },
      error: error => {
        this.loaderService.hide();
        this.reports.length = this.filteredReports.length = 0;
        // this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error fetching reports' });
        console.error('Error fetching reports', error);
      },
      complete: () => {
        this.loaderService.hide();
        console.log(this.overlayPanel);
        this.overlayPanel.hide();
      }
    });
  }

  getDriverReport(formData: any): void {
    this.loaderService.show();
    this.filterText = '';
    this.reportsService.driverReport(formData).pipe(
      tap(data => {
        this.columns = this.driverStatisticsCols;
        this.setTotals(data);
      }),
      map((data: any) => {
        return (data.driverStatistics as Array<any>).map(report => ({
          ...report,
          gross: `$${report.gross}`,
          deadHeadPercentage: `${report.deadHeadPercentage} %`,
        }));
      })
    ).subscribe({
      next: transformedData => {
        if(transformedData.length == 0) this.reports = [];
        else this.reports = transformedData;
        this.isRepGenerated = true;
        this.items.forEach(element => element.disabled = this.reports.length === 0);
        this.applyFilter();
      },
      error: error => {
        this.reports.length = this.filteredReports.length = 0;
        this.loaderService.hide();
        // this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error fetching reports' });
        console.error('Error fetching reports', error);
      },
      complete: () => {
        this.loaderService.hide();
        this.overlayPanel.hide();
      }
    });
  }
  
  setTotals(data: any) {
    let emptyMilesField = this.columns.find(f=>f.field === 'emptyMiles');
    let loadedMilesField = this.columns.find(f=>f.field === 'loadedMiles');
    let totalMilesField = this.columns.find(f=>f.field === 'totalMiles');
    let rateField = this.columns.find(f=>f.field === 'rate');
    let ratePerMileField = this.columns.find(f=>f.field === 'ratePerMile');
    let grossField = this.columns.find(f=>f.field === 'gross');

    if(emptyMilesField) emptyMilesField.totalValue = (data.totalEmptyMiles&&data.totalEmptyMilesPercent) ? data.totalEmptyMiles +' (' + data.totalEmptyMilesPercent + '%)' : '';
    if(loadedMilesField) {
      let tlmp = data.totalLoadedMilesPercent ? ' (' + data.totalLoadedMilesPercent + '%)' : '';
      loadedMilesField.totalValue = data.totalLoadedMiles ? (data.totalLoadedMiles + tlmp) : (data.totalLoaded ? data.totalLoaded : '');
    }
    if(totalMilesField) totalMilesField.totalValue = data.totalMiles ? data.totalMiles : '';
    if(rateField) rateField.totalValue = data.totalRate ? '$'+data.totalRate : '';
    if(ratePerMileField) ratePerMileField.totalValue = data.totalRatePerMile ? '$/mi '+data.totalRatePerMile.toFixed(2) : '';
    if(grossField) grossField.totalValue = data.totalGross ? '$'+data.totalGross.toFixed(2) : '';
  }

  generateClick() {
    let currentDay = new Date()
    let start: string = ''
    let end: string = ''

    if (this.selectedRangeType.code === 'custom' && this.dateRange) {
      start = formatDate(this.dateRange[0], 'yyyy-MM-dd', 'en-US');
      end = formatDate(this.dateRange[1], 'yyyy-MM-dd', 'en-US');
    } else {
      end = formatDate(currentDay, 'yyyy-MM-dd', 'en-US');
      currentDay.setDate(currentDay.getDate() - Number(this.selectedRangeType.code));
      start = formatDate(currentDay, 'yyyy-MM-dd', 'en-US');
    }

    this.headerReportDate = [new Date(start), new Date(end)];
    this.currentReport = this.selectedReportType;
    const formData = {
      bypickup: this.selectedtotalBy.code === 'pickUp', 
      broker: this.selectedBroker,
      carrier: this.selectedCarrier,
      dispatcher: this.selectedDispatcher,
      driver: this.selectedDriver,
      status: this.selectedStatus,
      vehicle: this.selectedVehicle,
      startDate: start,
      endDate: end,
      isDriver: false
    }
    this.isRepGenerated = false;
    switch (this.selectedReportType.code) {
      case 'RatePerMileDriver': 
        formData.isDriver = true;
        this.getRatePerMiReports(formData);
      break;
      case 'RatePerMileDispatcher': 
        formData.isDriver = false;
        this.getRatePerMiReports(formData);
      break;
      case 'TotalRevenueReport':
        this.getTotalRevenueReports(formData);
      break;
      case 'DriversStatistics':
        this.getDriverReport(formData);
      break;
    
      default:
        break;
    }
  }

  listChange(event: any) {
    if(event?.value?.code === 'custom') {
      this.isCustomCalendarVisible = true;
    } else {
      this.isCustomCalendarVisible = false;
      this.dateRange = undefined;
    }
  }

  setDisabledStateForm(state: boolean)  {
    this.filterFormDisabledState.totalByDisabled =
    this.filterFormDisabledState.brokerDisabled = 
    this.filterFormDisabledState.carrierDisabled = 
    this.filterFormDisabledState.vehicleDisabled = 
    this.filterFormDisabledState.dispatcherDisabled = 
    this.filterFormDisabledState.statusDisabled = state;
  }

  setRequiredStateForm(requiredStates: Partial<typeof this.filterFormRequiredState>) {
    for (const key in this.filterFormRequiredState) {
      if (this.filterFormRequiredState.hasOwnProperty(key)) {
        this.filterFormRequiredState[key] = requiredStates[key] ?? false;
      }
    }
  }

  isGenerateDisabled(): boolean {
    let generateState: boolean= false;

    switch (this.selectedReportType.code) {
      case "TotalRevenueReport":
        generateState = this.selectedDispatcher.length === 0;
        break;
      case "RatePerMileDispatcher":
        generateState = this.selectedDispatcher.length === 0;
        break;
      case "RatePerMileDriver":
        generateState = this.selectedDriver.length === 0;
        break;
      case "DriversStatistics":
        generateState = this.selectedDriver.length === 0;
        break;
    
      default:
        break;
    }

    return this.isCustomCalendarVisible
      ? generateState || !this.dateRange?.every(Boolean) 
      : generateState || !this.selectedRangeType;
  }

  backToFilter() {
    this.dateRange = undefined;
    this.selectedRangeType = this.rangeTypes[0];
    this.isCustomCalendarVisible = false;
  }

  getFilterData() {
    this.loaderService.show();
    
    const brokers = this.reportsService.getAllBrokers();
    const carriers = this.reportsService.getAllCarriers();
    const dispatchers = this.reportsService.getAllDispatchers();
    const drivers = this.reportsService.getAllDrivers();
    const driversLoadDetail = this.reportsService.getDriverSimple();
    const vehicles = this.reportsService.getAllVehicles();

    forkJoin([brokers, carriers, dispatchers, drivers, driversLoadDetail, vehicles]).subscribe({
      next: ([brokers, carriers, dispatchers, drivers, driversLoadDetail, vehicles]) => {
        this.allBrokers = brokers;
        this.allDispatchers = dispatchers;
        this.allDrivers = drivers;
        this.driverDetailSource = driversLoadDetail;
        this.allVehicles = vehicles;
        this.copyDriver = structuredClone(this.allDrivers);
        this.copyDispatcher = structuredClone(this.copyDispatcher);
        this.copyVehicle = structuredClone(this.allVehicles);
        this.allCarriers = carriers.filter((f:any)=> drivers.some(s=> s.carrierId === f.id));
      },
      error: (error) => {
        this.loaderService.hide();
        console.error(error);
      },
      complete: () => {
        this.loaderService.hide();
      }
    });
  }

  applyFilter() {
    if (this.filterText.length > 2) {
      const filterTextLower = this.filterText.toLowerCase();
  
      this.filteredReports = this.reports.filter((item) => {  
        // Generic search across all fields including nested fields
        const matches = (obj: any): boolean => {
          return Object.values(obj).some((value) => {
            if (typeof value === 'string') {
              return value.toLowerCase().includes(filterTextLower);
            } else if (typeof value === 'object' && value !== null) {
              return matches(value);
            }
            return false;
          });
        };

        if(this.currentReport.code === 'DriversStatistics') {
          const total = this.columns.find(col=> col.field === 'deadHeadPercentage');
          if(total) total.totalLabel = '';
        } else {
          const total = this.columns.find(col=> col.field === 'route');
          if(total) total.totalLabel = '';
        }

        return matches(item);
      });
      this.items.forEach(element => element.disabled = this.filteredReports.length === 0);
    } else {

      if(this.currentReport.code === 'DriversStatistics') {
        const total = this.columns.find(col=> col.field === 'deadHeadPercentage');
        if(total) total.totalLabel = 'Total:';
      } else {
        const total = this.columns.find(col=> col.field === 'route');
        if(total) total.totalLabel = 'Total:';
      }

      this.filteredReports = [...this.reports];
    }
  }
}
