import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AUTH_API_URL } from '../../../../services/app-injection-tokens';
import { Driver } from '../manage-pages/dispatch/interfaces/driver';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(
    private http: HttpClient,
    @Inject(AUTH_API_URL) private apiUrl: string
  ) { }

  totalRevenue(formData: any): Observable<any[]> {
    const payload = {
      bypickup: formData.bypickup,
      broker: formData.broker?.length ? formData.broker.map((m: any) => m.id).join(',') : '',
      carrier: formData.carrier?.length ? formData.carrier.map((m: any) => m.id).join(',') : '',
      dispatcher: formData.dispatcher?.length ? formData.dispatcher.map((m: any) => m.id).join(',') : '',
      driver: formData.driver?.length ? formData.driver.map((m: any) => m.id).join(',') : '',
      vehicle: formData.vehicle?.length ? formData.vehicle.map((m: any) => m.vehicle_id).join(',') : '',
      statuses: formData.status?.length ? formData.status.map((m: any) => m.code).join(',') : '',
      startDate: formData.startDate.toString(),
      endDate: formData.endDate.toString(),
    };    
  
    const params = new HttpParams()
      .append('userIds', payload.dispatcher)
      .append('statuses', payload.statuses)
      .append('driverIds', payload.driver)
      .append('carrierIds', payload.carrier)
      .append('vehiclesIds', payload.vehicle)
      .append('brokerIds', payload.broker)
      .append('bypickup', payload.bypickup)
      .set('startDate', payload.startDate)
      .set('endDate', payload.endDate)
  
    return this.http.get<any[]>(this.apiUrl + '/api/reports/total_revenue', { params });
  }

  ratePerMi(formData: any): Observable<any[]> {
    const payload = {
      bypickup: formData.bypickup,
      broker: formData.broker?.length ? formData.broker.map((m: any) => m.id).join(',') : '',
      carrier: formData.carrier?.length ? formData.carrier.map((m: any) => m.id).join(',') : '',
      dispatcher: formData.dispatcher?.length ? formData.dispatcher.map((m: any) => m.id).join(',') : '',
      driver: formData.driver?.length ? formData.driver.map((m: any) => m.id).join(',') : '',
      vehicle: formData.vehicle?.length ? formData.vehicle.map((m: any) => m.vehicle_id).join(',') : '',
      statuses: formData.status?.length ? formData.status.map((m: any) => m.code).join(',') : '',
      startDate: formData.startDate.toString(),
      endDate: formData.endDate.toString(),
      isDriver: formData.isDriver
    };    
  
    const params = new HttpParams()
      .append('userIds', payload.dispatcher)
      .append('statuses', payload.statuses)
      .append('driverIds', payload.driver)
      .append('carrierIds', payload.carrier)
      .append('vehiclesIds', payload.vehicle)
      .append('brokerIds', payload.broker)
      .append('bypickup', payload.bypickup)
      .set('startDate', payload.startDate)
      .set('endDate', payload.endDate)
      .set('isDriver', payload.isDriver)
  
    return this.http.get<any[]>(this.apiUrl + '/api/reports/rate_per_mi', { params });
  }

  driverReport(formData: any): Observable<any[]> {
    const payload = {
      driver: formData.driver?.length ? formData.driver.map((m: any) => m.id).join(',') : '',
      startDate: formData.startDate.toString(),
      endDate: formData.endDate.toString(),
    };    
  
    const params = new HttpParams()
      .append('driverIds', payload.driver)
      .set('startDate', payload.startDate)
      .set('endDate', payload.endDate)
  
    return this.http.get<any[]>(this.apiUrl + '/api/reports/driver_report', { params });
  }

  getAllBrokers(): Observable<any[]> {
    return this.http.get<any>(this.apiUrl + '/api/brokers');
  }

  getAllCarriers(): Observable<any> {
    return this.http.get<any[]>(this.apiUrl + '/api/carriers');
  }
  
  getAllDispatchers(): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl + '/api/users/dispatchers/all');
  }

  getAllDrivers(): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl + '/api/manage/drivers');
  }

  getAllVehicles(): Observable<any> {
    return this.http.get<any[]>(this.apiUrl + '/api/vehicles/all');;
  }

  getDriverSimple(showSupervisorDrivers: boolean = false): Observable<any> {
    const url = showSupervisorDrivers
      ? '/api/drivers/all_simple_view?show_supervisor_drivers=true'
      : '/api/drivers/all_simple_view';
    return this.http.get<Driver[]>(this.apiUrl + url);
  }

}
