<div class="users-wrapper">
  <div class="users-header">
    <div class="page-titles">
      <h2 class="title">Users</h2>
      <p class="subtitle">Track, manage and forecast your customers and orders.</p>
    </div>
    <div class="actions"></div>
  </div>
  <div class="users-content-card custom-height">
    <div class="users-content-card-header">
      <div class="user-header-card-left">
        <p class="user-header-card-title">Team members</p>
        <div class="badge">{{filteredUsers.length}} users</div>
      </div>
      <div class="user-header-card-right">
        <div class="block-input-icon-with-err search-user-input">
          <svg-icon style="height: 20px;" src="assets/svg/search-icon.svg"></svg-icon>
          <input
            type="text"
            mode="Search"
            placeholder="Search"
            (input)="onFilterChange($event.target.value)" />
        </div>
        <button appDebounceClick matRipple (debounceClick)="addUserClick()" class="global-btn btn-add">
          <img class="btn-icon" src="assets/svg/users-img.svg" alt="">
          <span class="btn-text">Add User</span>
        </button>
        <button appDebounceClick (debounceClick)="inviteUserClick()" class="global-btn btn-invite">
          <img class="btn-icon" src="assets/svg/plus.svg" alt="">
          <span class="btn-text">Invite</span>
        </button>
      </div>
    </div>
      <app-reusable-table 
        [data]="filteredUsers" 
        [columns]="columns" 
        (edit)="editRow($event)" 
        (delete)="deactivateHandler($event, 'delete')" 
        (select)="selectRows($event)" 
        [pageSize]="getNumbersOfRows()">
        <!-- <ng-template #expandedRowTemplate let-row>
          <div>
            <strong>Expanded Row:</strong> {{ row | json }}
          </div>
        </ng-template> -->
      </app-reusable-table>
  </div>
</div>
