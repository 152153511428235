import { Component, ContentChild, Input, Output, EventEmitter, TemplateRef, AfterViewInit, ElementRef, ViewChild, OnChanges, SimpleChanges, AfterContentInit, OnInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserRolesCode } from '../../../../../../enum/user-role.enum';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { loadColors } from '../../../timeline/types/load-status-color';
import { Status } from '../../../../../../enum/status.enum';
import { MatDialog } from '@angular/material/dialog';
import { ViewEditLoadComponent } from '../../../timeline/modal/edit/view-edit-load/view-edit-load.component';

interface DataRow {
  [key: string]: any;
}

@Component({
  selector: 'app-reusable-table',
  templateUrl: './reusable-table.component.html',
  styleUrls: ['./reusable-table.component.scss', '../../../../../main/main.module.styles.scss'],
  animations: [
    trigger('expandCollapse', [
      // state('collapsed', style({
      //   height: '0px',
      //   opacity: 0,
      //   padding: '0',
      //   margin: '0',
      //   overflow: 'hidden',
      //   display: 'none'
      // })),
      // state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      // state('expanded', style({
      //   height: '*',
      //   opacity: 1,
      //   padding: '*',
      //   margin: '*',
      //   display: 'table-row'
      // })),
      // transition('collapsed <=> expanded', [animate('300ms ease-in-out')])
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ]
})
export class ReusableTableComponent<T> {

  @Input() data: DataRow [] = [];
  @Input() carriers: any[] = [];
  @Input() dispatcers: any[] = [];
  @Input() drivers: any[] = [];
  @Input() columns: { field: string, header: string }[] = [];
  @Input() pageSize = 5;

  @Output() edit = new EventEmitter<T>();
  @Output() refresh = new EventEmitter();
  @Output() delete = new EventEmitter<T>();
  @Output() select = new EventEmitter<T[]>();

  @ContentChild('expandedRowTemplate') expandedRowTemplate?: TemplateRef<any>;
  @ContentChild('emptyState') emptyState?: TemplateRef<any>;
  
  @ViewChild('expandedRowTemplate', { read: ElementRef }) expandedContent?: ElementRef;
  @ViewChild('tableRow') tableRow!: ElementRef;
  @ViewChild('expandContent') expandContent!: ElementRef;
  
  constructor(
    // private jwtHelper: JwtHelperService,
    public dialog: MatDialog,
  ) {}

  expandedRow: DataRow | null = null;
  selectedRows: any[] = [];
  currentPage:number = 1;
  sortColumn: string | null = null;
  sortOrder: 'asc' | 'desc' | null = null;
  expandedCells = new Set<any>();
  roleTooltipPos: ConnectedPosition[] = [
    {
      originX: 'start',      // End (right) of the host element
      originY: 'bottom',   // Bottom of the host element
      overlayX: 'start',   // Start (left) of the overlay
      overlayY: 'top',     // Top of the overlay
      offsetX: -10,
      offsetY: -15,
    }
  ]
  
  typeTooltipPos: ConnectedPosition[] = [
    {
      originX: 'start',      // End (right) of the host element
      originY: 'bottom',   // Bottom of the host element
      overlayX: 'start',   // Start (left) of the overlay
      overlayY: 'top',     // Top of the overlay
      offsetX: -10,
      offsetY: -15,
    }
  ]

  ngOnChanges(changes: SimpleChanges): void {
    // if(changes['data'].currentValue.length > 0) this.setCommandsIconsDisabled(changes['data'].currentValue);
  }

  get paginatedData(): DataRow[] {
    let sortedData = [...this.data];
  
    if (this.sortColumn) {
      sortedData.sort((a, b) => {
        let aValue = (a as any)[this.sortColumn!];
        let bValue = (b as any)[this.sortColumn!];
  
        // Normalize the values if they contain special characters
        if (typeof aValue === 'string') {
          aValue = this.normalizeValue(aValue);
          if(typeof(aValue)==='string') aValue = aValue.toLocaleLowerCase();
        }
        if (typeof bValue === 'string') {
          bValue = this.normalizeValue(bValue);
          if(typeof(bValue)==='string') bValue = bValue.toLocaleLowerCase();
        }
  
        // Perform sorting
        if (aValue < bValue) return this.sortOrder === 'asc' ? -1 : 1;
        if (aValue > bValue) return this.sortOrder === 'asc' ? 1 : -1;
        return 0;
      });
    }
  
    const startIndex = (this.currentPage - 1) * this.pageSize;
    return sortedData.slice(startIndex, startIndex + this.pageSize);
  }
  
  normalizeValue(value: string): number | string {
    if (!value || typeof value !== 'string') {
      return value; // Return the original value if it's not a string
    }
    
    // Check if the value matches a date format (e.g., 'MM/DD/YYYY', 'YYYY-MM-DD')
    const datePattern = /^\d{4}-\d{2}-\d{2}$/;

    // If the value is a date string, do not normalize
    if (datePattern.test(value)) {
      return value; // Return the original date string
    }
  
    // Remove specific patterns
    const patternsToRemove = ['$', '$/mi'];
    patternsToRemove.forEach(pattern => {
      value = value.replace(new RegExp(`\\${pattern}`, 'g'), ''); // Remove patterns like '$' and '$/mi'
    });
  
    // Remove patterns like ' (100%)' or ' (any number%)'
    value = value.replace(/\s\(\d+%\)/g, ''); // Match ' (number%)' and remove it
  
    // Remove commas for consistent number parsing
    value = value.replace(/,/g, '');
  
    // Convert the cleaned value to a number if possible
    const numericValue = parseFloat(value);
  
    // Return the numeric value if valid, otherwise the cleaned string
    return isNaN(numericValue) ? value.trim() : numericValue;
  }  

  
  get totalPages(): number {
    return Math.ceil(this.data.length / this.pageSize);
  }

  // setCommandsIconsDisabled(rows: Array<any>) {
  //   const currUserEmail = localStorage.getItem('userEmail');
  //   const token: string = this.cookieService.get('token');
  //   const decodedToken = this.jwtHelper.decodeToken(token);
  //   const userRoleCode = decodedToken['role'].join();
  //   const userID = decodedToken['userId'];

  //   rows.forEach(row => {
  //     // Default to disabling both edit and delete buttons
  //     row.edit = row.delete = true;
    
  //     // Determine permissions based on user role
  //     switch (userRoleCode) {
  //       case UserRolesCode.ADMIN:
  //         // Admin can edit and delete all users
  //         row.edit = row.delete = false;
  //         break;
    
  //       case UserRolesCode.SUPERVISOR:
  //         // Supervisors can edit and delete their dispatchers only
  //         if (row.supervisorId === userID) {
  //           row.edit = row.delete = false; // Supervisor can edit and delete their dispatchers
  //         }
  //         break;
    
  //       case UserRolesCode.DISPATCHER:
  //         // Dispatchers cannot delete or edit any other users except themselves
  //         if (row.email === currUserEmail) {
  //           row.edit = false; // Dispatchers can edit themselves
  //         }
  //         break;
    
  //       default:
  //         // No special permissions for other roles or conditions
  //         break;
  //     }
    
  //     // Additional check: Users can always edit themselves
  //     if (row.email === currUserEmail) {
  //       row.edit = false; // User can edit themselves
  //       row.delete = true; // User cannot delete themselves
  //     }
  //   });
    
  // }

  toggleExpandRow(row: DataRow) {
    if(!this.expandedRowTemplate) {
      this.toggleRowSelection(row);
    } else {
      this.expandedRow = this.expandedRow === row ? null : row;
      // this.expandedRow = row;
      setTimeout(() => {
        console.log(row);
        // this.expandContent.nativeElement.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest" });
      }, 225);
    }
  }

  isRowExpanded(row: T): boolean {
    return this.expandedRow === row;
  }

  log(data:any) {
    console.log(data);
  }

  onEdit(row: T) {
    this.edit.emit(row);
  }

  onDelete(row: T) {
    this.delete.emit(row);
  }

  checkLoadStatus(status: Status) {    
    const modifStatus: string = status.replace(/\s+/g, '_').toUpperCase();
    if (modifStatus in loadColors) {
      return loadColors[modifStatus];
    } else {
      return '#F9FAFB';
    }
  }

  getLoadTypeColorBadge(type: string): string {
    switch (type.toLowerCase()) {
      case 'sb':
      case 'sp': return 'blue-badge'

      case 'ob':
      case 'od': return 'green-badge';

      case 'sh': return 'orange-badge';
    
      default: return '';
    }
  }

  createRow() {
    const newRow: any = {
      // Define the properties of the new row. For example:
      userActivityStatus: true,
      loadStatus: 'New',
      loadDetails: 'Some details',
      // Add other fields as required
    };
  
    // Add the new row to your data
    this.paginatedData.push(newRow);
  
    // If necessary, you may want to update the pagination or UI state
  }  

  isTotalVisible():boolean {
    return this.columns.some((s:any)=>s.showTotal)
  }

  isTotalForSalary():boolean {
    return this.data.some((s:any)=>s.salary);
  }

  isTotalHidden():boolean {
    return this.columns.some((s:any)=>s.totalLabel) //display empty footer
  }

  detailLoadClick(event: Event, row: any) {
    event.stopPropagation();
    // this.toggleExpandRow(row);
    const dialogRef = this.dialog.open(ViewEditLoadComponent, {
      data: {
        load: row.loadId,
        drivers: this.drivers,
        carriers: this.carriers,
        dispatcers: this.dispatcers,
      },
      panelClass: 'fix-edit-load-popup',
    });
    dialogRef.afterClosed().subscribe(async (resp) => {
      if(resp) this.refresh.emit();
    });
  }

  toggleRowSelection(row: any) {
    if (this.isSelected(row)) {
      this.selectedRows = this.selectedRows.filter(r => r !== row);
    } else {
      this.selectedRows.push(row);
    }
    this.select.emit(this.selectedRows);
  }

  isSelected(row: DataRow): boolean {
    return this.selectedRows.includes(row);
  }

  toggleSelectAll() {
    if (this.isAllSelected()) {
      this.selectedRows = [];
    } else {
      this.selectedRows = [...this.data];
    }
    this.select.emit(this.selectedRows);
  }

  isAllSelected(): boolean {
    return this.selectedRows.length === this.data.length;
  }

  goToPage(page: number) {
    if (page > 0 && page <= this.totalPages) {
      this.currentPage = page;
    }
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  getPagesArray(): (number | string)[] {
    const totalPages = this.totalPages;
    const currentPage = this.currentPage;
    const pages: (number | string)[] = [];

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 3) {
        pages.push(1, 2, 3, 4, '...', totalPages);
      } else if (currentPage >= totalPages - 2) {
        pages.push(1, '...', totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
      } else {
        pages.push(1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages);
      }
    }

    return pages;
  }

  sort(column: string) {
    if (this.sortColumn === column) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortColumn = column;
      this.sortOrder = 'asc';
    }
  }

  getSortClass(column: string): string {
    if (this.sortColumn === column) {
      return this.sortOrder !== 'asc' ? 'sort-asc' : 'sort-desc';
    }
    return '';
  }  
}
