import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { JwtHelperService } from '@auth0/angular-jwt';
import { forkJoin, map, tap } from 'rxjs';
import { DialogService } from '../../../../../services/dialog/dialog.service';
import { LoaderService } from '../../../../../services/loader/loader.service';
import { Item } from '../../../../../interfaces/item';
import { MenuItem, MessageService } from 'primeng/api';
import { TieredMenu } from 'primeng/tieredmenu';
import { Dispatcher } from '../../manage-pages/dispatch/interfaces/dispatcher';
import { formatDate } from '@angular/common';
import { OverlayPanel } from 'primeng/overlaypanel';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable, { RowInput } from 'jspdf-autotable';
import { SalaryService } from './salary.service';
import { InputNumberInputEvent } from 'primeng/inputnumber';
declare module 'jspdf' {
  interface jsPDF {
    lastAutoTable: {
      finalY: number;
    };
  }
}


@Component({
  selector: 'app-salary',
  templateUrl: './salary.component.html',
  styleUrls: ['./salary.component.scss', '../../../main.module.styles.scss']
})
export class SalaryComponent {

  @ViewChild(TieredMenu) tieredMenu!: TieredMenu;
  @ViewChild(OverlayPanel) overlayPanel!: OverlayPanel;

  constructor (
    private salaryService: SalaryService,
    private dialog: DialogService,
    private loaderService: LoaderService,
    private dialog1: MatDialog,
    private jwtHelper: JwtHelperService,
    private messageService: MessageService
  ) {
    this.items = [
      {
          label: 'PDF',
          disabled: true,
          command: () => this.saveDriverStatisticsPDF()
      },
      {
          label: 'XSLX',
          disabled: true,
          command: () => this.exportTableToExcel()
      },
    ]
  }
  public allDispatchers: Dispatcher[] = []
  private copyDispatcher: Dispatcher[] = [];
  public selectedDispatcher: Dispatcher[] = [];

  public selectAllFilters:boolean = false;
  public filterFormDisabledState: any = {
    dispatcherDisabled: false,
  }
  public filterFormRequiredState: any = {
    dispatcherRequired: false,
  }
  public reports: any[] = [];
  public filteredReports: any[] = [];
  public filterText: string = ''; 
  public percentVal!: number; 
  public bonusVal!: number; 
  public items: MenuItem[];
  public reportTypes: Item[] = [
    {code: 'Salary', name: 'Salary of Dispatcher'},
  ]
  public selectedReportType: Item = this.reportTypes[0];
  public currentReport: Item = structuredClone(this.selectedReportType);
  public rangeTypes: Item[] = [
    {code: '7', name: 'Last 7 days'},
    {code: '30', name: 'Last 30 days'},
    {code: '93', name: 'Last 3 months'},
    {code: '183', name: 'Last 6 months'},
    {code: '365', name: 'Last year'},
    {code: 'custom', name: 'Custom range'},
  ]
  public selectedRangeType: Item = this.rangeTypes[0];

  public totalBy : Item[] = [
    {code: 'pickUp', name: 'By pick up'},
    {code: 'delivery', name: 'By delivery'},
  ]
  public selectedtotalBy: Item = this.totalBy[0];
  public dateRange: Date[] | undefined;
  public headerReportDate: Date[] | undefined;
  public templates: Array<Item> = [
    {
      type: '',
      code: '1',
      name: 'myTemplate1',
      description: ''
    },
    {
      type: '',
      code: '2',
      name: 'myTemplate2',
      description: ''
    }
  ];
  public isRepGenerated: boolean = false;
  public isCustomCalendarVisible = false;
  public selectedTmpl: Array<Item> = new Array<Item>();

  public columns: any[] = [];

  private salaryCols = [
    { field: 'date', header: 'Date' },
    { field: 'name', header: 'Name' },
    { field: 'role', header: 'Role' },
    { field: 'totalGross', header: 'Gross' },
    { field: 'percent', header: '%', totalLabel: 'Salary:' },
    { field: 'bonus', header: 'Bonus', showTotal: true },
  ]

  ngOnInit(): void {
    this.getFilterData();

    this.setDisabledStateForm(false);
    this.setRequiredStateForm({dispatcherRequired: true});
  }

  ngOnDestroy(): void { }

  formatDateRange(dateRange: Date[]): string {
    if (!dateRange || dateRange.length !== 2) return '';
    const [start, end] = dateRange;
    return `${this.formatDate(start)} - ${this.formatDate(end)}`;
  }

  formatDate(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  }

  normalizeValue(value: string): number | string {
    if (!value || typeof value !== 'string') {
      return value; // Return the original value if it's not a string
    }
  
    // Remove specific patterns except ' (60%)'
    const patternsToRemove = ['$/mi'];
    patternsToRemove.forEach(pattern => {
      value = value.replace(new RegExp(`\\${pattern}`, 'g'), ''); // Remove patterns like '$' and '$/mi'
    });
  
    // Remove commas for consistent number parsing
    value = value.replace(/,/g, '');
  
    // Convert the cleaned value to a number if possible
    const numericValue = parseFloat(value);
  
    // Return the numeric value if valid, otherwise the cleaned string
    return isNaN(numericValue) ? value.trim() : numericValue;
  }

  saveDriverStatisticsPDF(): void {
    const doc = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      format: 'a4',
    });
  
    const pageHeight = doc.internal.pageSize.height;
    const marginY = 10;
    const textHeight = 10; // Înălțimea pentru `driverName` și `dateRange`
    let startY = marginY;
  
    const dateRange = this.headerReportDate
      ? `${this.formatDate(this.headerReportDate[0])} - ${this.formatDate(this.headerReportDate[1])}`
      : 'Date not available';
  
    const title = this.selectedReportType.name;
    doc.setFontSize(16);
    doc.text(title, 14, startY);
    startY += 10;
  
    const columns: string[] = this.salaryCols.map(col => col.header);
    const columnIndexMap: Record<string, number> = this.salaryCols.reduce((map, col, index) => {
      map[col.field] = index;
      return map;
    }, {} as Record<string, number>);
    
    let pageCount = 0;
    let tablesOnCurrentPage = 0;

    this.filteredReports.forEach((report, index) => {
      if (tablesOnCurrentPage >= 6) {
        doc.addPage();
        startY = marginY;
        pageCount = 0;
        tablesOnCurrentPage = 0;
      }
      const tableData: RowInput[] = [this.salaryCols.map(col => report[col.field])];
      
      const estimatedTableHeight = 10 + tableData.length * 10;
      const totalEstimatedHeight = estimatedTableHeight + textHeight;
  
      if (startY + totalEstimatedHeight > pageHeight - marginY) {
        doc.addPage();
        startY = marginY;
        pageCount++;
        tablesOnCurrentPage = 0;
      }

      const autoTableOptions: any = {
        head: [columns],
        body: tableData,
        startY: startY + 5,
        theme: 'striped',
        margin: { left: 2, right: 2 },
        styles: {
          fontSize: 10,
          cellPadding: 3,
        },
        headStyles: {
          fillColor: [40, 40, 40],
          textColor: [255, 255, 255],
        },
        alternateRowStyles: {
          fillColor: [245, 245, 245],
        },
        footStyles: {
          fillColor: '#F9FAFB',
          textColor: '#475467',
          fontStyle: 'bold',
        },
        // columnStyles: {
        //   [columnIndexMap['workingDays']]: { cellWidth: 30 },
        //   [columnIndexMap['daysOff']]: { cellWidth: 25 },
        //   [columnIndexMap['deadHeadPercentage']]: { cellWidth: 25 },
        //   [columnIndexMap['loadedMiles']]: { cellWidth: 35 },
        //   [columnIndexMap['totalMiles']]: { cellWidth: 28 },
        //   [columnIndexMap['ratePerMile']]: { cellWidth: 35 },
        //   [columnIndexMap['gross']]: { cellWidth: 28 },
        // },
      };
      const total = this.columns.find(col=> col.field === 'percent');
      // Adaugă footer doar pentru ultima tabelă
      // if (index === this.filteredReports.length - 1) {
        const footerRow: string[] = this.salaryCols.map((column:any) => {
          if (column.totalLabel) {
            return column.totalLabel;
          }
          if (column.showTotal) {
            return report.salary ? (this.normalizeValue(report.salary.toString())).toString() : '';
          }
          return '';
        });
  
        autoTableOptions.foot = (total.totalLabel && total.totalLabel.length > 0) ? [footerRow] : [''];
      // }
  
      autoTable(doc, autoTableOptions);
      startY = doc.lastAutoTable.finalY + 10;
      tablesOnCurrentPage++;
    });
  
    doc.save(`${this.currentReport.code} (${dateRange}).pdf`);
  }
  exportTableToExcel(): void {
    const tableData = this.addFooterRows(this.filteredReports);
  
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(tableData);
    const workbook: XLSX.WorkBook = { Sheets: { 'Data': worksheet }, SheetNames: ['Data'] };
    const dateRange = this.headerReportDate
      ? `${this.formatDate(this.headerReportDate[0])}-${this.formatDate(this.headerReportDate[1])}`
      : 'Date not available';
  
    XLSX.writeFile(workbook, `${this.currentReport.code}-${dateRange}.xlsx`);
  }
  
  addFooterRows(data: any[]): any[] {
    const tableWithFooters: any[] = [];
  
    data.forEach(row => {
      tableWithFooters.push(this.formatRowData(row)); // Add the current row
      tableWithFooters.push(this.getFooterRow(row)); // Add the footer for the current row
    });
  
    return tableWithFooters;
  }
  
  formatRowData(row: any): any {
    const formattedRow: any = {};
  
    this.columns.forEach(column => {
      formattedRow[column.header] = row[column.field];
    });
  
    return formattedRow;
  }
  
  getFooterRow(row: any): any {
    const footerRow: any = {};
  
    this.columns.forEach(column => {
      if (column.totalLabel) {
        footerRow[column.header] = column.totalLabel; // Static label for total
      } else if (column.showTotal) {
        footerRow[column.header] = row.salary; // Include the 'salary' field from the data row
      } else {
        footerRow[column.header] = ''; // Empty cell
      }
    });
  
    return footerRow;
  }
  
  
  showCustomCalendar(): void {
    this.isCustomCalendarVisible = true;
  }

  isRangeCompleted(): boolean {
    return this.dateRange && this.dateRange.every(date => date) || false;
  }

  isDateCompleted(): boolean {
    return this.headerReportDate && this.headerReportDate.every(date => date) || false;
  }

  onDateRangeSelected(): void {
    if (this.isRangeCompleted()) {
      this.isCustomCalendarVisible = false;
    } else {
      this.isCustomCalendarVisible = true;
    }
    this.headerReportDate = this.dateRange;
  }

  repTypeChange(event:any) {
    // this.reports.length = this.filteredReports.length = 0;
    this.clearFilters();
    // this.isRepGenerated = false;
    this.items.forEach(element => element.disabled = this.reports.length === 0);

    switch (event.value.code) {
      case "Salary":
        this.setDisabledStateForm(true);
        this.setRequiredStateForm({driverRequired: true});
      break;
    
      default:
        break;
    }
  }

  clearFilters() {
    this.selectedDispatcher = [];
    this.selectAllFilters = false;
  }

  getFilterTooltipText(): string {
    if(!this.selectedRangeType) return 'Select time range filter/Select all filters';
    else return 'Select all filters';
  }

  toggleSelectAll() {
    this.selectAllFilters = !this.selectAllFilters;

    if (this.selectAllFilters) {
      this.selectedDispatcher = [...this.allDispatchers];
    } else {
      this.selectedDispatcher = [];
    }
  }

  onCheckboxChange(selection: any[], item: any, event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      if (!selection.includes(item)) selection.push(item);
    } else {
      const index = selection.indexOf(item);
      if (index !== -1) {
        selection.splice(index, 1);
      }
    }
  }

  getDriverReport(formData: any): void {
    this.loaderService.show();
    this.filterText = '';

    const calculatePercentage = (percent: number, amount: number): number => {
      return (percent / 100) * amount;
    };
    
    const dateRange = this.headerReportDate
    ? `${this.formatDate(this.headerReportDate[0])} - ${this.formatDate(this.headerReportDate[1])}`
    : 'Date not available';

    this.salaryService.getSalary(formData).pipe(
      tap(data => {
        this.columns = this.salaryCols;
        // this.setTotals(data);
      }),
      map((data: any) => {
        return (data).map((salary:any) => ({
          ...salary,
          totalGross: `$${salary.totalGross}`,
          date: `${dateRange}`,
          percent: this.percentVal,
          bonus: salary.totalGross ? `$${this.bonusVal ?? 0}` : `$0`,
          salary: salary.totalGross ? `$${(calculatePercentage(this.percentVal,salary.totalGross) + (this.bonusVal ? this.bonusVal : 0)).toFixed(2)}` : 0
        }));
      })
    ).subscribe({
      next: transformedData => {
        if(transformedData.length == 0) this.reports = [];
        else this.reports = transformedData;
        this.isRepGenerated = true;
        this.items.forEach(element => element.disabled = this.reports.length === 0);
        this.applyFilter();
      },
      error: error => {
        this.reports.length = this.filteredReports.length = 0;
        this.loaderService.hide();
        // this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error fetching reports' });
        console.error('Error fetching reports', error);
      },
      complete: () => {
        this.loaderService.hide();
        this.overlayPanel.hide();
      }
    });
  }

  formatNumber(input: any) {
    if(!input.value) return;
    
    const value = input.value;
    const string = (value).toString();
    
    if (string.length === 1 || string.length > 3) return;

    const decimalPart = (value / Math.pow(10, string.length - 1)).toString().split('.');
    if (decimalPart[1]) {
        if (decimalPart[1].length < 3) {
            decimalPart[1] = decimalPart[1].substring(0, 2);
            this.percentVal = parseFloat(`${decimalPart[0]}.${decimalPart[1]}`);
        }
    }
}


  
  generateClick() {
    let currentDay = new Date()
    let start: string = ''
    let end: string = ''

    if (this.selectedRangeType.code === 'custom' && this.dateRange) {
      start = formatDate(this.dateRange[0], 'yyyy-MM-dd', 'en-US');
      end = formatDate(this.dateRange[1], 'yyyy-MM-dd', 'en-US');
    } else {
      end = formatDate(currentDay, 'yyyy-MM-dd', 'en-US');
      currentDay.setDate(currentDay.getDate() - Number(this.selectedRangeType.code));
      start = formatDate(currentDay, 'yyyy-MM-dd', 'en-US');
    }

    this.headerReportDate = [new Date(start), new Date(end)];
    this.currentReport = this.selectedReportType;
    const formData = {
      bypickup: this.selectedtotalBy.code === 'pickUp', 
      dispatcher: this.selectedDispatcher,
      startDate: start,
      endDate: end,
      isDriver: false
    }
    this.isRepGenerated = false;
    switch (this.selectedReportType.code) {
      case 'Salary':
        this.getDriverReport(formData);
      break;
    
      default:
        break;
    }
  }

  listChange(event: any) {
    if(event?.value?.code === 'custom') {
      this.isCustomCalendarVisible = true;
    } else {
      this.isCustomCalendarVisible = false;
      this.dateRange = undefined;
    }
  }

  setDisabledStateForm(state: boolean)  {
    this.filterFormDisabledState.dispatcherDisabled = state;
  }

  setRequiredStateForm(requiredStates: Partial<typeof this.filterFormRequiredState>) {
    for (const key in this.filterFormRequiredState) {
      if (this.filterFormRequiredState.hasOwnProperty(key)) {
        this.filterFormRequiredState[key] = requiredStates[key] ?? false;
      }
    }
  }

  isGenerateDisabled(): boolean {
    let generateState: boolean = false;

    switch (this.selectedReportType.code) {
      case "Salary":
        generateState = this.selectedDispatcher.length === 0 || !this.percentVal ;
      break;
    
      default:
        break;
    }

    return this.isCustomCalendarVisible
      ? generateState || !this.dateRange?.every(Boolean) 
      : generateState || !this.selectedRangeType;
  }

  backToFilter() {
    this.dateRange = undefined;
    this.selectedRangeType = this.rangeTypes[0];
    this.isCustomCalendarVisible = false;
  }

  getFilterData() {
    this.loaderService.show();

    const dispatchers = this.salaryService.getAllDispatchers();

    forkJoin([dispatchers]).subscribe({
      next: ([dispatchers]) => {
        this.allDispatchers = dispatchers;
        this.copyDispatcher = structuredClone(this.copyDispatcher);
      },
      error: (error) => {
        this.loaderService.hide();
        console.error(error);
      },
      complete: () => {
        this.loaderService.hide();
      }
    });
  }

  applyFilter() {
    if (this.filterText.length > 2) {
      const filterTextLower = this.filterText.toLowerCase();
  
      this.filteredReports = this.reports.filter((item) => {  
        // Generic search across all fields including nested fields
        const matches = (obj: any): boolean => {
          return Object.values(obj).some((value) => {
            if (typeof value === 'string') {
              return value.toLowerCase().includes(filterTextLower);
            } else if (typeof value === 'object' && value !== null) {
              return matches(value);
            }
            return false;
          });
        };

        // if(this.currentReport.code === 'Salary') {
        //   const total = this.columns.find(col=> col.field === 'deadHeadPercentage');
        //   if(total) total.totalLabel = '';
        // } else {
        //   const total = this.columns.find(col=> col.field === 'route');
        //   if(total) total.totalLabel = '';
        // }

        return matches(item);
      });
      this.items.forEach(element => element.disabled = this.filteredReports.length === 0);
    } else {

      // if(this.currentReport.code === 'Salary') {
      //   const total = this.columns.find(col=> col.field === 'deadHeadPercentage');
      //   if(total) total.totalLabel = 'Total:';
      // } else {
      //   const total = this.columns.find(col=> col.field === 'route');
      //   if(total) total.totalLabel = 'Total:';
      // }

      this.filteredReports = [...this.reports];
    }
  }


}